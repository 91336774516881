import { useLocation } from 'react-router-dom'

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PostAddIcon from '@mui/icons-material/PostAdd'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import HomeIcon from '@mui/icons-material/Home'

export const Navigation = () => {
  const location = useLocation()

  const adminPaths = [
    {
      index: 1,
      title: 'Inicio',
      color: location.pathname === '/' ? '#126C8A' :   '#fafafa',

      path: '/',
      icon: (
        <HomeIcon
          sx={{ color: location.pathname === '/' ? '#126C8A' : '#fafafa' }}
        />
      ),
    },
    {
      index: 2,
      title: 'Ingresar Cliente',
      path: '/crear-cliente',
      color: location.pathname === '/crear-cliente' ? '#126C8A' : '#fafafa',
      icon: (
        <PersonAddAlt1Icon
          sx={{
            color:
              location.pathname === '/crear-cliente' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 3,
      title: 'Proyectos',
      color: location.pathname === '/proyectos' ? '#126C8A' : '#fafafa',
      path: '/proyectos',
      icon: (
        <BackupTableIcon
          sx={{
            color: location.pathname === '/proyectos' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 4,
      title: 'Asignar Cláusulas',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',

      path: '/agregar-clausulas',
      icon: (
        <ViewSidebarIcon
          sx={{
            color:
              location.pathname === '/agregar-clausulas'
                ? '#126C8A'
                : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 5,
      title: 'Registrar Evento',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',

      path: '/ingresar-evento',
      icon: (
        <PostAddIcon
          sx={{
            color:
              location.pathname === '/ingresar-evento' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 6,
      title: 'Dar de Baja',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',

      path: '/dar-de-baja',
      icon: (
        <PersonRemoveAlt1Icon
          sx={{
            color: location.pathname === '/dar-de-baja' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 7,
      title: 'Proyectos Históricos',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',

      path: '/clientes-historicos',
      icon: (
        <ManageSearchIcon
          sx={{
            color:
              location.pathname === '/clientes-historicos'
                ? '#126C8A'
                : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 8,
      title: 'Mi Perfil',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',

      path: '/editar-perfil',
      icon: (
        <ManageAccountsIcon
          sx={{
            color:
              location.pathname === '/editar-perfil' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
    {
      index: 9,
      title: 'Cerrar Sesión',
      color: location.pathname === '/' ? '#126C8A' : '#fafafa',
      path: '/iniciar-sesion',
      icon: (
        <ExitToAppIcon
          sx={{
            color:
              location.pathname === '/iniciar-sesion' ? '#126C8A' : '#fafafa',
          }}
        />
      ),
    },
  ]
  return adminPaths
}
