import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import Swal from 'sweetalert2'
import { createNewClientQuery } from '../../../config/axios'
import ClientInfoDetail from './commons/ClientInfoDetail'
import 'react-phone-input-2/lib/style.css'
import MobilePhone from './commons/PhoneNumber'

/**
 * Componente para la creación de un nuevo cliente.
 *
 * Este componente permite al usuario ingresar los detalles de un nuevo cliente
 * y enviar esta información para ser almacenada.
 *
 * @returns {JSX.Element}
 */
const CreateNewClientDetails = () => {
  const [user, setUser] = useState({
    name: '',
    phone: '',
    email: '',
  })

  /**
   * Envía los detalles del nuevo cliente al servidor.
   *
   * @param {Object} user - Objeto que contiene los detalles del usuario.
   */
  const createUser = async (user) => {
    try {
      await createNewClientQuery('/client-api/crear-cliente', user)
      Swal.fire({
        icon: 'success',
        title: 'Usuario creado correctamente',
        showConfirmButton: false,
        timer: 1500,
      })

      setUser({ name: '', email: '' })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'Revise los campos ingresados e intente nuevamente. En caso de persistir, comuníquese con el administrador',
      })
    }
  }

  /**
   * Controla el cambio de los campos de texto y actualiza el estado del usuario.
   *
   * @param {Event} event - El evento de cambio del input.
   */
  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  /**
   * Maneja el envío del formulario y crea el usuario.
   *
   * @param {Event} event - El evento de envío del formulario.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (isValidUser(user)) {
      createUser(user)
    }
  }

  /**
   * Comprueba si los campos necesarios para crear un usuario son válidos.
   *
   * @param {Object} user - Objeto que contiene los detalles del usuario.
   * @returns {boolean} - Retorna true si el usuario es válido, false en caso contrario.
   */
  function isValidUser(user) {
    return !!user.name && !!user.email && !!user.phone
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
        <CardHeader subheader="Debes ingresar los datos en los campos respectivos" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <ClientInfoDetail
                  handleInputChange={handleInputChange}
                  name="name"
                  value={user.name}
                  label="Ingrese nombre del cliente"
                />
              </Grid>

              <Grid xs={12} md={6}>
                <MobilePhone
                  handleInputChange={handleInputChange}
                  value={user.phone}
                  name="phone"
                />
              </Grid>

              <Grid xs={12} md={6}>
                <ClientInfoDetail
                  handleInputChange={handleInputChange}
                  name="email"
                  value={user.email}
                  label="Ingrese correo electrónico del cliente"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            disabled={!isValidUser(user)}
            type="submit"
            variant="contained"
          >
            Crear
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default CreateNewClientDetails
