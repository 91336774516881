import React, { useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getProjectsByClientIdQuery } from '../../config/axios'

const SelectedClient = ({
  title,
  clients,
  onChange,
  label,
  subtitle,
  type,
}) => {
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    const getProjectsByClientId = async () => {
      try {
        const response = await getProjectsByClientIdQuery(
          '/projects-api/proyectos-por-id',
          selectedValue
        )
      } catch (error) {
        console.log(error)
      }
    }

    if (selectedValue !== '') {
      getProjectsByClientId()
    }
  }, [selectedValue])

  const handleValueChange = (event) => {
    const value = event.target.value
    setSelectedValue(value)
    onChange({ target: { name: type, value } })
  }

  return (
    <Box>
      {type !== 'project' && <h2>{title}</h2>}
      <p>{subtitle}</p>
      <FormControl fullWidth>
        <InputLabel id="selected-value-label">{label}</InputLabel>
        <Select
          labelId="selected-value-label"
          id="selected-value"
          label={label}
          value={selectedValue}
          onChange={handleValueChange}
        >
          {clients.map((client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectedClient
