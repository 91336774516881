import React from 'react'
import { TextField } from '@mui/material'

const CreateNewProjectFields = ({ data, label, handleProjectChange }) => {
  return (
    <>
      <TextField
        fullWidth
        label="Ingrese nombre del proyecto"
        name="name"
        value={data.name}
        required
        onChange={handleProjectChange}
      />
      <TextField
        fullWidth
        label="Ingrese tipo de proyecto"
        name="type"
        value={data.type}
        required
        onChange={handleProjectChange}
      />
      <TextField
        fullWidth
        label="Ingrese monto asignado al proyecto"
        name="price"
        value={data.price}
        required
        onChange={handleProjectChange}
      />{' '}
      <TextField
        fullWidth
        label="Ingrese duración del proyecto"
        name="time"
        value={data.time}
        required
        onChange={handleProjectChange}
      />
      <TextField
        fullWidth
        label="Ingrese lugar del proyecto"
        name="place"
        value={data.place}
        required
        onChange={handleProjectChange}
      />
    </>
  )
}

export default CreateNewProjectFields
