/**
 * Obtiene la parte de fecha de un valor en formato ISO 8601.
 * @param {string} ISODate - El valor en formato ISO 8601.
 * @returns {string} La parte de fecha en formato "yyyy-mm-dd".
 */
export const formatedDateWithoutTime = (ISODate) => {
  const date = new Date(ISODate)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  const formatedDate = date.toLocaleDateString('en-US', options)
  return formatedDate
}
