import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Box } from '@mui/material'

const Input = (props) => {
  const { handleInputChange, value, name } = props

  return (
    <Box>
      <PhoneInput
        specialLabel={'Nums'}
        country={'cl'}
        countryCodeEditable={false}
        inputStyle={{
          borderColor: (props.touched && props.error) && "red"
        }}
        onChange={(phoneNumber, countryData) => handleInputChange({ target: { value: `+${phoneNumber}`, name } })}
        value={value}
        {...props}
      />
      {(props.touched && props.error) && <p style={{ color: 'red' }} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{props.error}</p>}
    </Box>
  )
}

const index = (props) => {
  const { handleInputChange, value, name } = props

  return (
    <Input
      req={true}
      helperText={"Ingrese número de teléfono"}
      error={true}
      isSelect={false}
      handleInputChange={handleInputChange}
      value={value}
      name={name}
      {...props.input}
      {...props.meta}
      {...props.custom}
    />
  )
}

export default index
