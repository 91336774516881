import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AccountProfileDetails } from './AccountProfileDetails'
import { HomeContext } from '../../../context/HomeContext'
import { useNavigate } from 'react-router-dom'

const EditProfile = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])

  const [auth, setAuth] = useContext(HomeContext)
  console.log(auth)
  const [user, setUser] = useState({
    id: auth.id,
    name: auth.name,
    email: auth.email,
    currentPassword: '',
    password: '',
  })

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Editar Perfil</Typography>
          </div>
          <Grid container display="flex">
            <Grid item={true} xs={12}>
              <AccountProfileDetails
                auth={auth}
                setAuth={setAuth}
                user={user}
                setUser={setUser}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default EditProfile
