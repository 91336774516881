/**
 * Exporta la función para guardar todas las cláusulas activas.
 *
 * @param {Object[]} activeClauses - Lista de cláusulas activas a verificar y guardar.
 * @param {Object[]} historyClauses - Lista de objetos que contienen el 'asignedTo' y las cláusulas previamente guardadas.
 * @param {String} asignedTo - Asignación a la que pertenece la cláusula.
 * @returns {Object[]} Retorna una lista que combina las cláusulas en la historia con todas las cláusulas activas.
 *
 */
export const saveActiveClauses = (activeClauses, historyClauses, asignedTo) => {
  // Encuentra la entrada correspondiente en historyClauses por asignedTo
  const existingEntry = historyClauses.find(
    (entry) => entry.asignedTo === asignedTo
  )

  if (existingEntry) {
    // Si el 'asignedTo' ya existe, verifica que las cláusulas activas no estén ya en las cláusulas existentes.
    const uniqueClauses = activeClauses.filter(
      (ac) => !existingEntry.clauses.some((ec) => ec.clause === ac.clause)
    )

    existingEntry.clauses.push(...uniqueClauses)
  } else {
    // Si el 'asignedTo' no existe, crea una nueva entrada y la añade a historyClauses
    historyClauses.push({
      asignedTo,
      clauses: [...activeClauses],
    })
  }

  return historyClauses
}
