import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Box, Button } from '@mui/material'
import { convertDate } from '../../../../utils/formatedDate'

const EventDetailsConfirm = ({ button, event, onConfirm }) => {
  console.log(button)
  const renderInfoItem = (label, value) => (
    <Grid item xs={12}>
      <Typography variant="body1">{label}:</Typography>
      <Box border="1px solid" borderRadius={1} padding={1}>
        <Typography variant="body2" component="span">
          {value ? value : 'Sin datos'}
        </Typography>
      </Box>
    </Grid>
  )

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Grid container spacing={2}>
      {renderInfoItem('Dimensión', event.dimension)}
      {renderInfoItem('Categoría', event.category)}
      {renderInfoItem('Subcategoría', event.subcategory)}
      {renderInfoItem('Observaciones', event.observations)}
      {renderInfoItem('Medidas de mitigación', event.mitigation_measures)}
      {renderInfoItem('Impacto o consecuencia', event.impact_consequence)}
      {renderInfoItem(
        'Necesidad de mayor plazo',
        event.need_for_extension ? 'Sí' : 'No'
      )}
      {renderInfoItem(
        'Necesidad de mayor presupuesto',
        event.more_price ? 'Sí' : 'No'
      )}
      {renderInfoItem(
        'Fecha del evento',
        event.event_date && convertDate(event.event_date)
      )}
      {renderInfoItem(
        'Evidencia',
        event.evidence && event.evidence[0] && event.evidence[0].name
      )}

      {button === 'no' && (
        <Grid item xs={12} sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{ marginRight: 1 }}
          >
            Confirmar
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

EventDetailsConfirm.propTypes = {
  event: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default EventDetailsConfirm
