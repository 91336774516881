import React, { useEffect, useState } from 'react'
import { Grid, Card, CardHeader, Divider } from '@mui/material'
import Swal from 'sweetalert2'

import { HistoryClauses } from './common/HistoryClauses'
import {
  createClausesQuery,
  getProjectsByClientIdQuery,
} from '../../../config/axios'
import { saveActiveClauses } from './functions/saveActiveClauses'
import ToggleClausesComponent from './common/ToggleClausesComponent'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import AddIcon from '@mui/icons-material/Add'
import { ClientProjectSelectors } from './selectors/ClientProjectSelectors'
import { DimensionCategorySelectors } from './selectors/DimensionCategorySelectors'
import { ClauseSection } from './selectors/ClauseSection'

const maxInputFields = 999

export const AddClausesForm = ({ clients }) => {
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedDimension, setSelectedDimension] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [historyClauses, setHistoryClauses] = useState([])

  const [projects, setProjects] = useState({})
  const [fields, setFields] = useState([
    {
      clientId: '',
      projectId: '',
      dimension: '',
      category: '',
      subcategory: 'No aplica',
      clauses: [
        {
          clause: '',
          observation: '',
          active: false,
        },
      ],
    },
  ])

  const handleClauseClick = (fieldIndex, clauseIndex) => {
    const newFields = [...fields]
    const currentActiveState = newFields[fieldIndex].clauses[clauseIndex].active

    newFields[fieldIndex].clauses[clauseIndex].active = !currentActiveState
    setFields(newFields)
  }

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value)
  }

  const handleProjectChange = (value) => {
    setSelectedProject(value)
  }

  const handleFieldChange = (fieldIndex, clauseIndex, event) => {
    const newFields = [...fields]
    newFields[fieldIndex].clauses[clauseIndex][event.target.name] =
      event.target.value
    setFields(newFields)
  }

  const handleDimensionChange = (event) => {
    setSelectedDimension(event.target.value)
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
  }

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value)
  }

  const handleKeyDown = (fieldIndex, clauseIndex, event) => {
    if (
      (event.key === 'Backspace' || event.key === 'Delete') &&
      fields[fieldIndex].clauses[clauseIndex].clause === '' &&
      fields[fieldIndex].clauses[clauseIndex].observation === '' &&
      fields[fieldIndex].clauses.length > 1
    ) {
      event.preventDefault()
      const newFields = [...fields]
      newFields[fieldIndex].clauses.splice(clauseIndex, 1)
      setFields(newFields)
    } else if (
      event.key === 'Enter' &&
      fields[fieldIndex].clauses.length < maxInputFields
    ) {
      event.preventDefault()
      const newFields = [...fields]
      newFields[fieldIndex].clauses.push({ clause: '', observation: '' })
      setFields(newFields)
    }
  }

  const handleRadioClick = (fieldIndex, clauseIndex) => {
    const newFields = [...fields]
    newFields[fieldIndex].clauses[clauseIndex].active =
      !newFields[fieldIndex].clauses[clauseIndex].active
    setFields(newFields)
  }

  const handleClick = async () => {
    try {
      await createClausesQuery(
        'clauses-api/create-clauses',
        fields,
        localStorage.getItem('token')
      )

      Swal.fire({
        icon: 'success',
        title: '¡Cláusulas creadas!',
        text: 'Las clásulas han sido creadas correctamente',
      })

      setSelectedDimension(null)
      setSelectedCategory(null)
      setSelectedSubcategory(null)

      const activeClauses = fields[0].clauses.filter((clause) => clause.active)
      const asignedTo = `${selectedDimension} - ${selectedCategory} - ${
        selectedSubcategory || 'No aplica'
      }`
      const newHistoryClauses = saveActiveClauses(
        activeClauses,
        historyClauses,
        asignedTo
      )

      setHistoryClauses(newHistoryClauses)

      const newFields = fields.map((field) => ({
        ...field,
        dimension: '',
        category: '',
        subcategory: 'No aplica',
        clauses: field.clauses.map((clause) => ({
          ...clause,
          active: false,
        })),
      }))

      setFields(newFields)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'No se han podido crear las cláusulas correctamente',
      })
    }
    return
  }

  // Efectos
  const updateFields = () => {
    const newFields = fields.map((field) => ({
      ...field,
      clientId: selectedClient,
      projectId: selectedProject,
      dimension: selectedDimension,
      category: selectedCategory,
      subcategory: selectedSubcategory,
    }))
    setFields(newFields)
  }
  useEffect(() => {
    updateFields()
    setIsButtonDisabled(!selectedDimension)
  }, [
    selectedClient,
    selectedProject,
    selectedDimension,
    selectedCategory,
    selectedSubcategory,
  ])

  useEffect(() => {
    if (selectedClient) {
      const getProjectsByClientId = async () => {
        try {
          const response = await getProjectsByClientIdQuery(
            '/projects-api/proyectos-por-id',
            selectedClient
          )
          setProjects(response.data.projects)
        } catch (error) {
          console.log(error)
        }
      }
      getProjectsByClientId()
    }
  }, [selectedClient])

  return (
    <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
      <CardHeader subheader="Asignar cláusulas a los distintos eventos de los proyectos" />
      <ClientProjectSelectors
        clients={clients}
        projects={projects}
        handleClientChange={handleClientChange}
        handleProjectChange={handleProjectChange}
      />
      <Divider
        textAlign="left"
        style={{
          marginTop: 10,
        }}
      >
        Dimensiones, Categorías y Subcategorías
      </Divider>
      <DimensionCategorySelectors
        selectedDimension={selectedDimension}
        setSelectedDimension={setSelectedDimension}
        handleDimensionChange={handleDimensionChange}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleCategoryChange={handleCategoryChange}
        selectedSubcategory={selectedSubcategory}
        setSelectedSubcategory={setSelectedSubcategory}
        handleSubcategoryChange={handleSubcategoryChange}
      />{' '}
      <Divider
        textAlign="left"
        style={{
          marginTop: 10,
        }}
      >
        Cláusulas
      </Divider>
      <Grid>
        <ToggleClausesComponent
          primaryIcon={<AddIcon />}
          secondaryIcon={<FormatListBulletedIcon />}
          primaryLabel="Registrar nuevas cláusulas"
          secondaryLabel="Seleccionar cláusulas anteriores"
          handleFieldChange={handleFieldChange}
          handleKeyDown={handleKeyDown}
          handleRadioClick={handleRadioClick}
          handleClauseClick={handleClauseClick}
          fields={fields}
        />
      </Grid>
      <ClauseSection
        isButtonDisabled={isButtonDisabled}
        handleClick={handleClick}
      />
      <Divider
        textAlign="left"
        style={{
          marginTop: 10,
        }}
      >
        Cláusulas Registradas Anteriormente
      </Divider>
      <Grid>
        <HistoryClauses historyClauses={historyClauses} />
      </Grid>
    </Card>
  )
}
