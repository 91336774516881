import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import Swal from 'sweetalert2'
import { editProfileQuery } from '../../../config/axios'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import CustomTextField from './commons/CustomTextField'

/**
 * AccountProfileDetails es un componente de React que permite al usuario editar sus detalles de perfil.
 * Cuando el usuario envía el formulario, los detalles se actualizan en el servidor y se muestra un mensaje de éxito.
 *
 * @param {object} props - Propiedades del componente
 * @param {object} props.user - Objeto que representa al usuario actual
 * @param {function} props.setUser - Función para establecer el estado del usuario
 * @param {object} props.auth - Objeto que contiene información de autenticación del usuario
 * @param {function} props.setAuth - Función para establecer el estado de autenticación del usuario
 */
export const AccountProfileDetails = ({ user, setUser, auth, setAuth }) => {
  const navigate = useNavigate()
  const [flag, setFlag] = useState(true)

  const handleSubmit = (event) => {
    event.preventDefault()
    editProfile(user)
  }

  /**
   * Actualiza los detalles del perfil del usuario en el servidor.
   *
   * @param {object} user - Objeto que representa al usuario actual
   */
  const editProfile = async (user) => {
    try {
      await editProfileQuery('/auth-api/editar-perfil', user)

      Swal.fire(
        'Se ha editado el perfil correctamente',
        'Inicia sesión nuevamente',
        'success'
      )

      setUser({
        id: '',
        name: '',
        email: '',
        password: '',
      })

      setAuth({
        id: '',
        isAuth: false,
        token: '',
        name: '',
        email: '',
        accessToken: '',
      })

      localStorage.clear()

      navigate('/iniciar-sesion')
    } catch (error) {
      console.log(error)
      const { msg } = error.response.data
      Swal.fire('Ha ocurrido un error', msg, 'error')
    }
  }

  /**
   * Actualiza el estado del usuario cuando cambia un campo de entrada.
   * También ajusta el estado de la bandera que determina si se puede enviar el formulario.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de entrada
   */
  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })

    setFlag(!(user.currentPassword && user.password))
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card
        sx={{
          flexGrow: 1,
          px: 2,
          mx: 2,
        }}
      >
        <CardHeader
          subheader="Puedes editar esta información"
          title="Edita tu perfil"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  helperText="Ingrese su nombre"
                  name="name"
                  onChange={handleInputChange}
                  value={user.name}
                  disabled
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  helperText="Correo electrónico"
                  name="email"
                  value={user.email}
                  required
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <CustomTextField
                  required
                  fullWidth
                  type="password"
                  label="Contraseña actual"
                  helperText="Debes ingresar tu contraseña actual"
                  onChange={handleInputChange}
                  value={user.currentPassword}
                  name="currentPassword"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <CustomTextField
                  required
                  fullWidth
                  type="password"
                  label="Contraseña nueva"
                  helperText="Debes ingresar la nueva contraseña"
                  onChange={handleInputChange}
                  value={user.password}
                  name="password"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button disabled={flag} onClick={handleSubmit} variant="contained">
            Guardar cambios
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
