import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, TextField } from '@mui/material'
import { updateClientsDataQuery } from '../../../../config/axios'
import { HomeContext } from '../../../../context/HomeContext'
import Swal from 'sweetalert2'

/**
 * EditProfileDetailsModal es un componente de React que muestra un modal
 * para editar detalles del perfil de un cliente. Los cambios se guardan
 * al hacer clic en el botón "Confirmar".
 *
 * @param {object} props - Propiedades del componente
 * @param {object} props.client - Objeto que representa al cliente
 * @param {function} props.setClient - Función para establecer el estado del cliente
 * @param {function} props.onConfirm - Función a ejecutar cuando se confirma la edición
 * @param {function} props.handleInputChange - Función para manejar cambios en los campos de entrada
 * @param {function} props.onClose - Función a ejecutar cuando se cierra el modal
 *
 * @returns {JSX.Element}
 */
const EditProfileDetailsModal = ({
  setClient,
  client,
  onConfirm,
  handleInputChange,
  onClose,
}) => {
  const [auth] = useContext(HomeContext)

  /**
   * Actualiza el estado del cliente cuando cambia un campo de entrada.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de entrada
   */
  const handleChange = (event) => {
    setClient({
      ...client,
      [event.target.name]: event.target.value,
    })
  }

  /**
   * Crea un campo de entrada con su correspondiente etiqueta.
   *
   * @param {string} label - Etiqueta del campo de entrada
   * @param {string} value - Valor actual del campo de entrada
   * @param {string} name - Nombre del campo de entrada (se usa como clave para el objeto cliente)
   *
   * @returns {JSX.Element}
   */
  const renderInfoItem = (label, value, name) => (
    <Grid item xs={12}>
      <TextField
        fullWidth
        name={name}
        label={label}
        onChange={handleChange}
        required
        value={value}
      />
    </Grid>
  )

  /**
   * Maneja la confirmación de la edición del perfil del cliente.
   * Si la operación es exitosa, se cierra el modal y se muestra un mensaje de éxito.
   * En caso contrario, se cierra el modal y se muestra un mensaje de error.
   */
  const handleConfirm = async () => {
    try {
      const response = await updateClientsDataQuery(
        '/editar-perfil-cliente',
        client,
        auth.token,
        auth.accessTokenQuery
      )
      onClose()

      if (response.status === 204) {
        Swal.fire(
          'Ha ocurrido un error',
          'No se realizaron cambios en el perfil del cliente',
          'error'
        )
      } else {
        Swal.fire('Cambio exitoso', response.data.msg, 'success')
      }
    } catch (error) {
      const { msg } = error.response.data
      onClose()
      Swal.fire('Error', msg, 'error')
    }
  }

  return (
    <Grid container spacing={2}>
      {renderInfoItem('Nombre del cliente', client.name, 'name')}
      {renderInfoItem('Correo electrónico del cliente', client.email, 'email')}
      {renderInfoItem('Número telefónico del cliente', client.phone, 'phone')}

      <Grid item xs={12}>
        <Button
          variant="contained"
          disabled
          type="submit"
          onClick={handleConfirm}
          sx={{ marginRight: 1 }}
        >
          Confirmar
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
      </Grid>
    </Grid>
  )
}

EditProfileDetailsModal.propTypes = {
  client: PropTypes.object.isRequired,
  setClient: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default EditProfileDetailsModal
