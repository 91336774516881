import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import CreateNewProjectDetails from './CreateNewProjectDetails'
import { useEffect, useState } from 'react'
import { getActiveClientsQuery } from '../../../config/axios'
import Swal from 'sweetalert2'
import Loading from '../../../sections/loading/Loading'

/**
 * Componente para la creación de un nuevo proyecto.
 *
 * Este componente permite al usuario ingresar los detalles de un nuevo proyecto
 * y enviar esta información para ser almacenada.
 *
 * @returns {JSX.Element}
 */
const CreateNewProject = () => {
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { clients } = await getActiveClientsQuery('/client-api/clientes')
        setClients(clients)
        setLoading(false)
      } catch (error) {
        const { msg } = error.response?.data || {}
        const title =
          error.response?.status === 500
            ? 'No se encuentra autenticado. Por favor, inicie sesión'
            : 'Ha ocurrido un error'

        Swal.fire({
          icon: 'error',
          title: title,
          text: msg?.error || msg,
        })
      }
    }

    fetchData()
  }, [])

  return (
    // Box sirve como contenedor principal
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Crear un nuevo proyecto</Typography>
          {loading ? (
            <Loading />
          ) : (
            <Grid container>
              <Grid xs={12} item>
                <CreateNewProjectDetails clients={clients} />
              </Grid>
            </Grid>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default CreateNewProject
