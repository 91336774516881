import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useState } from 'react'

const Selected = ({ data, onChange, label, setProjectSelected }) => {
  const [selected, setSelected] = useState('')

  const handleChange = (event) => {
    setSelected(event.target.value)
    if (event.target) {
      onChange(event.target.value)
    }
  }

  // Verificar si data es un array antes de usar map
  if (!Array.isArray(data)) {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={!Array.isArray(data)}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
        >
          <MenuItem>{label}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={!Array.isArray(data)}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          name="selected" // Agrega la propiedad name al elemento Select
          value={selected}
          onChange={handleChange}
        >
          {data.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default Selected
