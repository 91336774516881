import React from "react";
import { TextField } from "@mui/material";

const InvolvedFields = ({ data, handleInvolvedChange }) => {
  return (
    <>
      <TextField
        fullWidth
        label="Nombre del involucrado"
        name="name"
        value={data.name}
        required
        onChange={handleInvolvedChange}
      />
      <TextField
        fullWidth
        label="Cargo"
        name="position"
        required
        value={data.position}
        onChange={handleInvolvedChange}
      />
      <TextField
        fullWidth
        label="Empresa"
        name="company"
        required
        value={data.company}
        onChange={handleInvolvedChange}
      />
    </>
  );
};

export default InvolvedFields;
