import { TextField } from "@mui/material"

const CustomTextField = ({ name, label, helperText, value, onChange, disabled, type = 'text' }) => (
  <TextField
    fullWidth
    helperText={helperText}
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    required
    type={type}
    label={label}
  />
)

export default CustomTextField