/**
 * Converts an ISO 8601 date string to a readable date format.
 * @param {string} ISODate - The ISO 8601 date string.
 * @returns {string} The date in a readable format.
 */
export const convertDate = (ISODate) => {
  const date = new Date(ISODate)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  }
  const readableDate = date.toLocaleString('en-US', options)
  return readableDate
}
