import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getActiveClientsQuery } from '../../../config/axios'
import { AddClausesForm } from './AddClausesForm'
import { HomeContext } from '../../../context/HomeContext'

const AddClauses = () => {
  const navigate = useNavigate()
  const [clients, setClients] = useState([])
  const [auth, setAuth] = useContext(HomeContext)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])

  useEffect(() => {
    const getClientsAndProjects = async () => {
      const { clients } = await getActiveClientsQuery('/client-api/clientes')
      setClients(clients)
      setLoading(false)
    }

    getClientsAndProjects()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Container>
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Asignar Cláusulas</Typography>
          </div>

          <Grid container display="flex" justifyContent="center">
            <AddClausesForm auth={auth} setAuth={setAuth} clients={clients} />
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default AddClauses
