import { TextField } from '@mui/material'
import React from 'react'

const ClientInfoDetail = ({ handleInputChange, name, value, label }) => {
  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      onChange={handleInputChange}
      required
      value={value}
      type={name === 'phone' ? 'number' : undefined}

    />
  )
}

export default ClientInfoDetail