import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteAccountData from './DeleteAccountData'
import { getActiveClientsQuery } from '../../../config/axios'
import NotHaveEvents from '../events/NotHaveEvents'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../sections/loading/Loading'

const DeleteClient = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getClients()
  }, [setClients])

  const getClients = async () => {
    try {
      const { clients } = await getActiveClientsQuery('/client-api/clientes')
      setClients(clients)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClientDeleted = async () => {
    await getClients() // Recargar la lista de clientes después de eliminar uno
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Container>
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Remover Cliente o Proyecto</Typography>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <Grid container display="flex" justifyContent="center">
                {clients.length === 0 ? (
                  <NotHaveEvents
                    buttonText="Agregar cliente"
                    redirect="/crear-cliente"
                    text="Aún no tienes clientes agregados"
                  />
                ) : (
                  <Grid item lg={12}>
                    <Grid item xs={12} md={12} lg={12} marginBottom={5}>
                      <DeleteAccountData
                        clients={clients}
                        onClientDeleted={handleClientDeleted}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default DeleteClient
