import { useState, useEffect } from 'react'

/**
 * Custom hook to get the current screen dimensions (width and height).
 * This hook will update the size state every time the screen is resized.
 * It makes use of the 'resize' window event.
 *
 * @returns {Array} - An array where the first element is the width and the second is the height.
 */
export function useScreenDimensions() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}
