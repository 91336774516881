import React, { useContext, useEffect, useState } from 'react'
import { getProjectsQuery } from '../../../config/axios'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Projects from '../project/Projects'
import { HomeContext } from '../../../context/HomeContext'
import useAuthentication from '../../../hooks/useAuthentication'

const ClientsHistory = () => {
  const [auth] = useContext(HomeContext)
  const [allProjects, setAllProjects] = useState([])
  const [resume, setResume] = useState([])

  const isAuthenticated = useAuthentication(auth.id) // Use the hook
  const rows = allProjects.map((project) => ({
    ...project,
    createdAt: new Date(project.createdAt).toLocaleString(),
    updatedAt: new Date(project.updatedAt).toLocaleString(),
  }))

  useEffect(() => {
    const getProjects = async () => {
      try {
        const { projects, events } = await getProjectsQuery(
          '/projects-api/proyectos-historicos',
          localStorage.getItem('token'),
          localStorage.getItem('accessTokenQuery')
        )
        setAllProjects(projects)
        setResume(events)
      } catch (error) {
        console.log(error)
        // handleServerError(error)
      }
    }

    // Only fetch clients if the user is authenticated
    if (isAuthenticated) {
      getProjects()
    }
  }, []) // Add isAuthenticated as a dependency

  return (
    <Box flexGrow={1} display="flex">
      <Container>
        <Stack spacing={3}>
          <Typography variant="h4" textAlign="center">
            CLIENTES HISTÓRICOS
          </Typography>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Projects resume={resume} rows={rows} />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default ClientsHistory
