import { Box, Card, CardContent, Unstable_Grid2 as Grid } from '@mui/material'

const DashboardDetails = ({ dashboard }) => {
  const link =
    'https://app.powerbi.com/view?r=eyJrIjoiYzM1N2Y5OTgtYmVmYi00MjRiLWJjZjktNzdmOTQ5MGZhYWJhIiwidCI6ImQwZDljYzdlLTc3MzQtNGRjYS1hODZjLThlOTg3ZDhhOTQzYSJ9'

  return (
    <Card
      sx={{
        px: 2,
        mx: 2,
        my: 1,
      }}
    >
      <CardContent>
        <Box sx={{ m: -1.5 }}>
          <Grid container spacing={3}>
            <iframe
              width="1100px"
              height="600px"
              className="dashboard-view"
              src={link}
              title="Reporte Agencia Polux"
            ></iframe>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

export default DashboardDetails
