import React, { useState, useEffect } from 'react'
import { Button, Card, CardActions } from '@mui/material'
import Swal from 'sweetalert2'
import {
  deleteAccountOrProjectQuery,
  getProjectsByClientIdQuery,
} from '../../../config/axios'
import SelectedClient from '../../Common/SelectedClient'

const DeleteAccountData = ({ clients }) => {
  const [clientId, setClientId] = useState('')
  const [projects, setProjects] = useState([])
  const [toDelete, setToDelete] = useState({
    clientId: '',
    projectId: '',
  })

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value

    setToDelete((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  console.log(toDelete)
  useEffect(() => {
    setClientId('')
  }, [clients])

  useEffect(() => {
    const getProjectsByClientId = async () => {
      try {
        const response = await getProjectsByClientIdQuery(
          '/projects-api/proyectos-por-id',
          toDelete.clientId,
          localStorage.getItem('token')
        )
        setProjects(response.data.projects)
      } catch (error) {
        console.log(error)
      }
    }

    if (toDelete.clientId !== '') {
      getProjectsByClientId()
    }
  }, [clientId, toDelete.clientId, clients])

  const handleSubmit = (event) => {
    event.preventDefault()
    deleteClientAccount(clientId)
  }

  const deleteClientAccount = async (id) => {
    const jwt = localStorage.getItem('token')

    try {
      const response = await deleteAccountOrProjectQuery(
        '/client-api/eliminar',
        toDelete,
        jwt
      )

      Swal.fire({
        icon: 'success',
        title: response.data.msg,
      })

      setToDelete({
        clientId: '',
        projectId: '',
      })
    } catch (error) {
      console.log(error)
      const { msg } = error.response.data

      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error.',
        text: msg,
      })
    }
  }

  return (
    <Card sx={{ flexGrow: 1, px: 2, mx: 2 }}>
      <SelectedClient
        title="Seleccione un cliente"
        onChange={handleChange}
        clients={clients}
        label="Selecciona el cliente a remover"
        subtitle="Recordar que los datos del cliente no se borrarán, solamente dejará de aparecer como un cliente activo"
        type="clientId"
      />
      {toDelete.clientId && (
        <SelectedClient
          title="Seleccione un proyecto"
          onChange={handleChange}
          clients={projects}
          label="Selecciona el proyecto a remover"
          subtitle="Recordar que los datos del proyecto no se borrarán, solamente dejará de aparecer como un cliente activo"
          type="projectId"
        />
      )}
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          disabled={!toDelete.clientId}
          onClick={handleSubmit}
          variant="contained"
        >
          Guardar cambios
        </Button>
      </CardActions>
    </Card>
  )
}

export default DeleteAccountData
