import React, { useState } from 'react' // No olvides importar useState
import { Box, Button } from '@mui/material'
import { ClauseList } from './ClauseList'
import ClausesSelector from './ClausesSelector'

const ToggleClausesComponent = ({
  primaryIcon,
  secondaryIcon,
  primaryLabel,
  secondaryLabel,
  fields,
  handleFieldChange,
  handleKeyDown,
  handleRadioClick,
  handleClauseClick,
}) => {
  const [localFlag, setLocalFlag] = useState(false)

  const handleToggle = () => {
    setLocalFlag((prevFlag) => !prevFlag)
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Button
        variant="outlined"
        color="primary"
        startIcon={localFlag ? primaryIcon : secondaryIcon}
        onClick={handleToggle} 
      >
        {localFlag ? primaryLabel : secondaryLabel}
      </Button>

      {localFlag ? (
        <ClausesSelector
          fields={fields}
          handleClauseClick={handleClauseClick}
        />
      ) : (
        <ClauseList
          fields={fields}
          handleFieldChange={handleFieldChange}
          handleKeyDown={handleKeyDown}
          handleRadioClick={handleRadioClick}
        />
      )}
    </Box>
  )
}

export default ToggleClausesComponent
