import { Grid } from '@mui/material'
import Selected from './Selected'

export const ProjectSelector = ({ projects, onChange }) => (
  <Grid item xs={12} sm={6} lg={5.96}>
    <Selected
      title="Cliente"
      data={projects}
      label="Seleccionar proyecto"
      type="project"
      onChange={onChange}
    />
  </Grid>
)
