export function esValidForm(data, type) {
  // Validación para el tipo 'event'
  if (type === 'event')
    return (
      !!data.client_id &&
      !!data.dimension &&
      !!data.category &&
      !!data.event_date
    )

  // Validación para el tipo 'user'
  if (type === 'user') return !!data.name && !!data.phone && !!data.email

  // Si no se proporciona un tipo válido, devuelve false
  return false
}
