// External packages
import React, { useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

// Utils
import { useScreenDimensions } from './utils/useScreenDimensions'

// Context
import { HomeContext, HomeProvider } from './context/HomeContext'

// Components - Pages
import Home from './components/pages/home/Home'
import Login from './components/pages/login/Login'
import Clients from './components/pages/client/Clients'
import CreateClient from './components/pages/client/CreateClient'
import ClientsHistory from './components/pages/client-history/ClientsHistory'
import Event from './components/pages/events/Event'
import DeleteClient from './components/pages/delete/DeleteClient'
import ProjectsPage from './components/pages/project/ProjectPage'
import CreateNewProject from './components/pages/project/CreateNewProject'
import AddClauses from './components/pages/add-clauses/AddClauses'
import EditProfile from './components/pages/edit-profile/EditProfile'

// Components - Navigation
import MainDrawer from './components/pages/Nav/MainDrawer'
import PermanentDrawerRight from './components/pages/Nav/PermanentDrawer'
import { DataTable } from './components/pages/events/DataTable'
import { DataTableClauses } from './components/pages/add-clauses/DateTableClauses'

const AppRouting = () => {
  const [width] = useScreenDimensions()
  const [auth, setAuth] = useContext(HomeContext)
  const Drawer = width < 780 ? MainDrawer : PermanentDrawerRight

  return (
    <HomeProvider value={[auth, setAuth]}>
      <Routes>
        <Route path="/iniciar-sesion" element={<Login />} />
        <Route element={<Drawer />}>
          <Route path="/" element={<Home />} />
          <Route path="/crear-cliente" element={<CreateClient />} />
          <Route path="/clientes" element={<Clients />} />
          <Route path="/proyectos" element={<ProjectsPage />} />
          <Route path="/proyectos/crear" element={<CreateNewProject />} />
          <Route path="/proyectos/:slug/:id" element={<DataTable />} />
          <Route
            path="/clausulas/:clientName/:clientId/:projectName/:projectId"
            element={<DataTableClauses />}
          />
          <Route path="/agregar-clausulas" element={<AddClauses />} />
          <Route path="/ingresar-evento" element={<Event />} />
          <Route path="/dar-de-baja" element={<DeleteClient />} />
          <Route path="/editar-perfil" element={<EditProfile />} />
          <Route path="/clientes-historicos" element={<ClientsHistory />} />
        </Route>
      </Routes>
    </HomeProvider>
  )
}

export default AppRouting
