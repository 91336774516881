import { useCallback, useEffect, useState } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridCsvExportMenuItem,
} from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import Loading from '../../../sections/loading/Loading'
import { getProjectClausesQuery } from '../../../config/axios'
import NotHaveEvents from '../events/NotHaveEvents'
import { columnsClauses } from '../../../utils/columnsClauses'

const csvOptions = { delimiter: ';' }

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarContainer>
  )
}

export const DataTableClauses = () => {
  const { clientId, projectId } = useParams()
  const [rows, setRows] = useState([])
  const [flag, setFlag] = useState(false)
  const [clientInfo, setClientInfo] = useState({
    clientName: '',
    projectName: '',
  })

  const getClient = useCallback(async () => {
    try {
      const { data } = await getProjectClausesQuery(
        '/clauses-api/clauses',
        clientId,
        projectId,
        localStorage.getItem('token')
      )

      let rowsWithIds = []

      if (Array.isArray(data)) {
        rowsWithIds = data
      } else if (data.clauses && Array.isArray(data.clauses)) {
        rowsWithIds = data.clauses
        setClientInfo({
          clientName: data.clientName,
          projectName: data.projectName,
        })
      } else {
        throw new Error('Unexpected data format')
      }

      rowsWithIds = rowsWithIds.map((row, index) => ({ id: index, ...row }))

      setRows(rowsWithIds)
      setFlag(true)
    } catch (error) {
      console.log(error)
    }
  }, [clientId, projectId])

  useEffect(() => {
    getClient()
  }, [getClient])

  console.log(rows, clientInfo)

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      {flag ? (
        <Container>
          <Stack spacing={3}>
            {rows.length === 0 ? (
              <NotHaveEvents
                buttonText="Agregar Cláusulas"
                redirect="/agregar-clausulas"
                text="Aún no hay cláusulas asociadas a este proyecto"
              />
            ) : (
              <>
                <div>
                  <Typography variant="h3" textAlign="center">
                    {clientInfo.clientName}
                  </Typography>
                </div>
                <Stack spacing={1}>
                  <Typography variant="h4">
                    Clásulas del proyecto: {clientInfo.projectName}
                  </Typography>
                  <Stack>
                    <Typography>
                      En esta tabla puedes ver todos las cláusulas asociado a
                      este proyecto
                    </Typography>
                  </Stack>
                </Stack>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <DataGrid
                      rows={rows}
                      columns={columnsClauses}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      disableRowSelectionOnClick
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Stack>
        </Container>
      ) : (
        <Loading />
      )}
    </Box>
  )
}
