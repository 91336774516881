import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const useAuthentication = (authId) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !localStorage.getItem('token') ||
      !localStorage.getItem('name') ||
      !localStorage.getItem('email') ||
      !localStorage.getItem('accessTokenQuery')
    ) {
      localStorage.clear()
      navigate('/iniciar-sesion')
      setIsAuthenticated(false)
    }
  }, [navigate, authId])

  return isAuthenticated
}

export default useAuthentication
