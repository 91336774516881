import { Grid } from '@mui/material'
import { ClientSelector } from '../common/ClientSelector '
import { ProjectSelector } from '../common/ProjectSelector'

export const ClientProjectSelectors = ({
  clients,
  projects,
  handleClientChange,
  handleProjectChange,
}) => (
  <Grid
    container
    display="flex"
    justifyContent="space-between"
    alignItems="baseline"
  >
    <ClientSelector clients={clients} onChange={handleClientChange} />
    <ProjectSelector projects={projects} onChange={handleProjectChange} />
  </Grid>
)
