import * as React from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/system'
import Fade from '@mui/material/Fade'
import EventDetailsConfirm from '../pages/events/commons/EventDetailsConfirm'
import { Button, Modal } from '@mui/material'
import EditProfileDetailsModal from '../pages/edit-profile/commons/EditProfileDetailsModal'

export default function EventModalConfirm({
  button,
  isDisabled,
  setClient,
  handleInputChange,
  title,
  type,
  client,
  confirm,
  open,
  handleOpen,
  handleClose,
  event,
}) {
  console.log(type, button)
  return (
    <div>
      <Button disabled={isDisabled} variant="contained" onClick={handleOpen}>
        {title}
      </Button>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        {type === 'event' ? (
          <Fade in={open}>
            <Box sx={style}>
              <h2 id="transition-modal-title"> {title} </h2>
              <EventDetailsConfirm
                button={button}
                event={event}
                onConfirm={confirm}
              />
            </Box>
          </Fade>
        ) : (
          <Fade in={open}>
            <Box sx={style}>
              <h2 id="transition-modal-title"> {title} </h2>
              <EditProfileDetailsModal
                setClient={setClient}
                handleInputChange={handleInputChange}
                client={client}
                onConfirm={confirm}
                onClose={handleClose}
              />
            </Box>
          </Fade>
        )}
      </StyledModal>
    </div>
  )
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  )
})

Backdrop.propTypes = {
  open: PropTypes.bool,
}

const StyledModal = styled(Modal)`
  overflow: auto;
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = (theme) => ({
  overflow: 'auto',
  position: 'absolute',
  height: '80%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
})
