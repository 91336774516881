import { Grid } from '@mui/material'
import { CATEGORIES, DIMENSIONS, SUBCATEGORIES } from '../../../../utils/events'
import { DimensionSelector } from '../common/DimensionSelector'
import { CategorySelector } from '../common/CategorySelector'
import { SubcategorySelector } from '../common/SubcategorySelector'

export const DimensionCategorySelectors = ({
  selectedDimension,
  setSelectedDimension,
  handleDimensionChange,
  selectedCategory,
  setSelectedCategory,
  handleCategoryChange,
  selectedSubcategory,
  setSelectedSubcategory,
  handleSubcategoryChange,
}) => (
  <Grid
    container
    display="flex"
    justifyContent="space-between"
    alignItems="end"
    rowSpacing={1}
    marginY={2}
  >
    <Grid item xs={12} sm={6} lg={3.98}>
      <DimensionSelector
        dimensions={DIMENSIONS}
        selectedDimension={selectedDimension}
        setSelectedDimension={setSelectedDimension}
        onChange={handleDimensionChange}
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={3.98}>
      <CategorySelector
        categories={CATEGORIES.filter(
          (category) => category.depends === selectedDimension
        )}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        onChange={handleCategoryChange}
      />
    </Grid>
    <Grid item xs={12} sm={6} lg={3.98}>
      <SubcategorySelector
        subcategories={SUBCATEGORIES.filter(
          (subcategory) => subcategory.depends === selectedCategory
        )}
        selectedSubcategory={selectedSubcategory}
        setSelectedSubcategory={setSelectedSubcategory}
        onChange={handleSubcategoryChange}
      />
    </Grid>
  </Grid>
)
