import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { getProjectsByClientIdQuery } from '../../config/axios'

const Selected = ({ title, data, onChange, label, subtitle, type }) => {
  const [selectedValue, setSelectedValue] = useState('')
  const [selectedClient, setSelectedClient] = useState('')

  useEffect(() => {
    const getProjectsByClientId = async () => {
      try {
        const response = await getProjectsByClientIdQuery(
          '/projects-api/proyectos-por-id',
          selectedValue
        )
      } catch (error) {}
    }

    getProjectsByClientId()
  }, [selectedValue])

  const handleValueChange = (event) => {
    setSelectedValue(event.target.value)
    setSelectedClient(event.target.value)
    onChange(event.target.value)
  }

  return (
    <Box>
      {type !== 'project' && <h2>{title}</h2>}
      <p>{subtitle}</p>
      <FormControl fullWidth>
        <InputLabel id="selected-value-label">{label}</InputLabel>
        <Select
          labelId="selected-value-label"
          id="selected-value"
          label={label}
          value={selectedValue}
          onChange={handleValueChange}
        >
          {data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {}
      </FormControl>
    </Box>
  )
}

export default Selected
