import React from 'react'
import { Box, Button } from '@mui/material'
import SelectedInvolved from './SelectedInvolved'
import InvolvedFields from './InvolvedFields'

const ToggleComponent = ({
  flag,
  onToggle,
  primaryIcon,
  secondaryIcon,
  primaryLabel,
  secondaryLabel,
  data,
  handleInvolvedChange,
  handleInvolvedChangeForm
}) => {

  

  return (
    <Box display='flex' justifyContent='center' flexDirection='column'>
      <Button
        variant="outlined"
        color="primary"
        onClick={onToggle}
        startIcon={flag ? primaryIcon : secondaryIcon}
      >
        {flag ? primaryLabel : secondaryLabel}
      </Button>

      {flag ? (
        <SelectedInvolved
          data={data}
          onChange={handleInvolvedChangeForm}
          label="Seleccione a la persona involucrada"
        />
      ) : (
        <InvolvedFields
          data={data}
          handleInvolvedChange={handleInvolvedChange}
        />
      )}
    </Box>
  )
}

export default ToggleComponent
