export const groupEventsById = (events) => {
  const tempObject = {}

  events.forEach((event) => {
    if (!tempObject[event.id]) {
      // Si el evento no está en el objeto temporal, lo copiamos tal cual
      // pero convertimos evidence_filename en un array
      tempObject[event.id] = {
        ...event,
        evidence_filename: event.evidence_filename
          ? [event.evidence_filename]
          : [],
      }
    } else {
      // Si el evento ya existe en el objeto temporal, agregamos el evidence_filename al array
      if (
        event.evidence_filename &&
        !tempObject[event.id].evidence_filename.includes(
          event.evidence_filename
        )
      ) {
        tempObject[event.id].evidence_filename.push(event.evidence_filename)
      }
    }
  })

  // Transformamos el objeto temporal de nuevo a un array
  return Object.values(tempObject)
}
