import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotHaveEvents = ({ buttonText, redirect, text }) => {
  const navigate = useNavigate()

  const handleClick = (route) => {
    navigate(route)
  }

  return (
    <Box
      height="50vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h6"> {text} </Typography>
      <Grid container spacing={3} justifyContent="center" mt={1}>
        <Grid item>
          <Button variant="contained" onClick={() => handleClick(redirect)}>
            {buttonText}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => handleClick('/')}>
            Volver a inicio
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NotHaveEvents
