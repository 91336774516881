import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeContext } from '../../../context/HomeContext'
import { getProjectsQuery } from '../../../config/axios'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import NotHaveEvents from '../events/NotHaveEvents'
import Projects from './Projects'
import useAuthentication from '../../../hooks/useAuthentication'
import Loading from '../../../sections/loading/Loading'

const ProjectPage = () => {
  const [auth] = useContext(HomeContext)
  const navigate = useNavigate()
  const [allProjects, setAllProjects] = useState([])
  const [resume, setResume] = useState([])
  const [loading, setLoading] = useState(true)

  const isAuthenticated = useAuthentication(auth.id) // Use the hook
  const rows = allProjects.map((project) => ({
    ...project,
    createdAt: new Date(project.createdAt).toLocaleString(),
    updatedAt: new Date(project.updatedAt).toLocaleString(),
  }))

  const handleClick = (route) => {
    navigate(route)
  }

  useEffect(() => {
    const getProjects = async () => {
      try {
        const { projects, events } = await getProjectsQuery(
          '/projects-api/proyectos',
          localStorage.getItem('token'),
          localStorage.getItem('accessTokenQuery')
        )
        setResume(events)
        setAllProjects(projects)
        setLoading(false)
      } catch (error) {
        console.log(error)
        // handleServerError(error)
      }
    }

    // Only fetch clients if the user is authenticated
    if (isAuthenticated) {
      getProjects()
    }
  }, [isAuthenticated])

  return (
    <Box flexGrow={1} display="flex">
      <Container>
        <Stack spacing={3}>
          <Typography variant="h4" textAlign="center">
            PROYECTOS
          </Typography>
          {loading ? (
            <Loading />
          ) : allProjects.length === 0 ? (
            <NotHaveEvents
              buttonText="Agregar proyecto"
              redirect="/crear-proyecto"
              text="Aún no tienes proyectos agregados"
            />
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Projects
                    rows={rows}
                    resume={resume}
                    projects={allProjects}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => handleClick('/proyectos/crear')}
                >
                  Crear nuevo proyecto
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default ProjectPage
