import { Fragment } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { generateSlug } from '../../../utils/generateSlug'
import { Row } from './common/Row'

const Projects = ({ rows, resume }) => {
  function transformData(obj) {
    const result = {}
    obj.forEach((project) => {
      const { client_name, ...rest } = project
      if (!result[client_name]) {
        result[client_name] = { client_name, projects: [] }
      }
      result[client_name].projects.push(rest)
    })
    return Object.values(result)
  }

  const data = transformData(resume)

  return (
    <Fragment>
      {data.map((client, index) => (
        <Fragment key={client.client_name + index}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            fontWeight="bold"
          >
            {client.client_name}
          </Typography>
          <Table style={{ marginBottom: 50 }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">Nombre del Proyecto</TableCell>
                <TableCell align="center">Último Cambio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: cambiar index por valor id unico */}
              {client.projects.map((project, index) => (
                <Row
                  key={index}
                  project={project}
                  clientName={generateSlug(client.client_name)}
                />
              ))}
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </Fragment>
  )
}

export default Projects
