import { Box, Button, Grid, Tooltip } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useScreenDimensions } from '../../utils/useScreenDimensions'

export function OptionsButtons({
  options,
  rows,
  name,
  selectedValue,
  onChange,
  direction,
  title,
  subtitle,
  responsive,
}) {
  const [width] = useScreenDimensions()

  const handleOptionClick = (value) => {
    onChange(name, value)
  }

  return (
    <>
      <Grid>
        <h2>{title}</h2>
        <Box
          component="div"
          sx={{ display: 'block' }}
          bgcolor="#fff4e5"
          color="#b69c78"
          marginY={2}
          textAlign="center"
          alignItems="center"
          border="1px solid"
          borderRadius={1}
          padding={1}
        >
          <HelpIcon fontSize="14" /> {subtitle}
        </Box>
        <Grid
          justifyContent="space-evenly"
          display="flex"
          flexDirection={direction}
          flexWrap="wrap"
        >
          {options.map((option, index) => (
            <Tooltip key={index} title={option} placement="top">
              {responsive ? (
                <Button
                  style={{
                    width: width < 600 ? '100%' : rows === '2' ? '48%' : '33%',
                    margin: '1px',
                  }}
                  name={name}
                  onClick={() => handleOptionClick(option)}
                  value={option}
                  variant={selectedValue === option ? 'contained' : 'outlined'}
                >
                  {option}
                </Button>
              ) : (
                <Button
                  style={{
                    margin: '1px',
                  }}
                  name={name}
                  onClick={() => handleOptionClick(option)}
                  value={option}
                  variant={selectedValue === option ? 'contained' : 'outlined'}
                >
                  {option}
                </Button>
              )}
            </Tooltip>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
