import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
} from '@mui/material'

export const ClauseField = ({
  field,
  fieldIndex,
  clauseIndex,
  handleFieldChange,
  handleKeyDown,
}) => {
  const handleRadio = () => {
    handleFieldChange(fieldIndex, clauseIndex, {
      target: { name: 'active', value: !field.active },
    })
  }

  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={2} lg={2} marginTop={3} marginRight={1}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`outlined-adornment-clause-${field.id}`}>
            Cláusula
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-clause-${field.id}`}
            value={field.clause}
            name="clause"
            label="Clausula"
            onChange={(event) =>
              handleFieldChange(fieldIndex, clauseIndex, event)
            }
            onKeyDown={(event) => handleKeyDown(fieldIndex, clauseIndex, event)}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: field.active ? '#126C8A' : null,
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={9} lg={9} marginTop={3} marginRight={1}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`outlined-adornment-observation-${field.id}`}>
            Observación
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-observation-${field.id}`}
            value={field.observation}
            name="observation"
            label="Observacion"
            onChange={(event) =>
              handleFieldChange(fieldIndex, clauseIndex, event)
            }
            onKeyDown={(event) => handleKeyDown(fieldIndex, clauseIndex, event)}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: field.active ? '#126C8A' : null,
              },
            }}
          />
        </FormControl>
      </Grid>
      <FormControlLabel
        value={field.active}
        name="active"
        control={
          <Radio
            style={{
              marginTop: '50%',
              marginLeft: '50%',
            }}
            checked={field.active}
            onClick={handleRadio}
            sx={{
              '&.Mui-checked': {
                color: field.active ? '#126C8A' : 'gray',
              },
            }}
          />
        }
      />
    </Grid>
  )
}
