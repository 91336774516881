import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const SelectedInvolved = ({ title, data, onChange, label }) => {
  const [selectedInvolved, setSelectedInvolved] = useState('');

  const handleChange = (event) => {
    setSelectedInvolved(event.target.value);
    onChange(event.target.value);
  }

  return (
    <Box>
      <h2>{title}</h2>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"> {label} </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          name="selectedInvolved" // Agrega la propiedad name al elemento Select
          value={selectedInvolved}
          onChange={handleChange}
        >
          {
            data.map((involved) => (
              <MenuItem key={involved.id} value={involved.id}> {involved.name} </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectedInvolved