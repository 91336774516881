import axios from 'axios'

const dashAxios = axios.create({
  baseURL:
    'https://gestor-de-proyectos-backend-production.up.railway.app/cerberus/api/v1',
  // baseURL: 'http://localhost:3002/cerberus/api/v1',
})

// export const getDashboardQuery = async (url, role, query, token) => {
//   const response = await dashAxios.get(`${url}/${role}/${query}`, {
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   })
//   return response.data
// }

/**
 * Obtiene la lista de clientes activos desde la API.
 * @param {string} url - La URL de la API de clientes activos.
 * @returns {Promise} - Una promesa que se resuelve con los datos de los clientes activos.
 */
export const getActiveClientsQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene la lista de todos los clientes desde la API.
 * @param {string} url - La URL de la API de todos los clientes.
 * @returns {Promise} - Una promesa que se resuelve con los datos de todos los clientes.
 */
export const getAllClientsQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Verifica el token de acceso mediante una consulta a la API.
 * @param {string} url - La URL de la API para verificar el token.
 * @param {string} token - El token de acceso a verificar.
 * @returns {Promise} - Una promesa que se resuelve con los datos de la verificación del token.
 */
export const checkAccessTokenQuery = async (url, token) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene un resumen de eventos mediante una consulta a la API.
 * @param {string} url - La URL de la API para obtener el resumen de eventos.
 * @returns {Promise} - Una promesa que se resuelve con los datos del resumen de eventos.
 */
export const getEventsResumeQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene la lista de todos los involucrados mediante una consulta a la API.
 * @param {string} url - La URL de la API para obtener la lista de involucrados.
 * @returns {Promise} - Una promesa que se resuelve con los datos de todos los involucrados.
 */
export const getAllInvolveds = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}

/**
 * Obtiene los detalles de un cliente por su slug y ID mediante una consulta a la API.
 * @param {string} url - La URL de la API para obtener los detalles del cliente.
 * @param {string} slug - El slug del cliente.
 * @param {string} id - El ID del cliente.
 * @returns {Promise} - Una promesa que se resuelve con los detalles del cliente.
 */
export const getClientBySlug = async (url, slug, id) => {
  const response = await dashAxios.get(`${url}/${slug}/${id}`)
  return response.data
}

export const createNewEventQuery = async (
  url,
  formData,
  data,
  jwt,
  accessToken,
  userId
) => {
  const response = await dashAxios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${jwt}`,
      AccessToken: `${accessToken}/${userId}`,
    },
    params: data,
  })
  console.log(response)
  return response
}

export const getProjectsByClientIdQuery = async (url, clientId, jwt) => {
  const response = await dashAxios.get(`${url}/${clientId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return response
}

export const getProjectsByIdQuery = async (url, id, jwt) => {
  const response = await dashAxios.get(`${url}/${id}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return response
}

export const getDashboardQuery = async (url, role, query) => {
  const response = await dashAxios.get(`${url}/${role}/${query}`)
  return response.data
}

export const createUserAccount = async (url, user, token) => {
  const newUser = await dashAxios.post(url, user, token, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  })
  return newUser
}

export const createNewClientQuery = async (url, client) => {
  const response = await dashAxios.post(url, client)
  return response
}

export const createNewProjectQuery = async (url, project) => {
  console.log(project)
  const response = await dashAxios.post(url, project)
  return response
}

export const queryLogin = async (url, user) => {
  const response = await dashAxios.post(url, user)
  return response
}

export const getAllUsersQuery = async (url) => {
  const response = await dashAxios.get(url)
  return response.data
}
export const editProfileQuery = async (url, user) => {
  const response = await dashAxios.put(url, user)
  return response.data
}

export const createOrEditDashboardQuery = async (url, dashboard, token) => {
  const response = await dashAxios.post(url, dashboard, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

/**
 * Realiza una solicitud PATCH para eliminar una cuenta.
 * @param {string} url - La URL de la ruta de eliminación de la cuenta.
 * @param {string} clientId - El clientId de la cuenta que se va a eliminar.
 * @param {string} jwt - El token JWT utilizado para la autorización.
 * @param {string} accessToken - El token de acceso utilizado para la autorización.
 * @returns {Promise} - Una promesa que se resuelve en los datos de la respuesta de la solicitud.
 * @throws {Error} - Se lanza un error si la solicitud no es exitosa.
 */
export const deleteAccountOrProjectQuery = async (url, data, jwt) => {
  const response = await dashAxios.patch(`${url}/${data.clientId}`, data, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  console.log(response)
  return response
}

export const updateClientsDataQuery = async (url, client, jwt, accessToken) => {
  const response = await dashAxios.patch(`${url}/${client.id}`, client, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      // AccessToken: `${accessToken}`,
    },
  })

  return response
}

export const getProjectsQuery = async (url, jwt, accessToken) => {
  const response = await dashAxios.get(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      AccessToken: `${accessToken}`,
    },
  })
  return response.data
}

export const getProjectClausesQuery = async (url, clientId, projectId, jwt) => {
  const response = await dashAxios.get(`${url}/${clientId}/${projectId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return response
}

export const createClausesQuery = async (url, data, jwt) => {
  const response = await dashAxios.post(url, data, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
  return response
}

export default dashAxios
