import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button } from '@mui/material'
import { Fragment, useState } from 'react'
import { formatedDateWithoutTime } from '../../../utils/formatedDateWithoutTime'
const Row = ({ clientName, clientData }) => {
  const [open, setOpen] = useState(false)

  const handleDownloadFile = (filename) => {
    // if (filename) {
    //   const fileUrl = `http://localhost:3002/cerberus/api/v1/images/${encodeURIComponent(
    //     filename
    //   )}`
    //   window.open(fileUrl, '_blank')
    // }
  }

  return (
    <Fragment>
      <h2>{clientName}</h2> {/* Mostrando el nombre del cliente */}
      <TableContainer component={Paper}>
        {Object.entries(clientData).map(([projectName, data]) => (
          <Table aria-label="collapsible table" key={projectName}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Nombre del proyecto</TableCell>
                <TableCell>último cambio realizado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {projectName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatedDateWithoutTime(data.updatedAt)}
                </TableCell>
              </TableRow>
              <Collapse in={open} timeout="auto">
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Historial de cambios recientes
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Dimensión</TableCell>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Subcategoría</TableCell>
                        <TableCell>Observaciones</TableCell>
                        <TableCell>Id de la evidencia</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>UpdatedAt</TableCell>
                        <TableCell>filename</TableCell>
                        <TableCell>event_date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data ? (
                        data.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.dimension}
                            </TableCell>
                            <TableCell>{item.category}</TableCell>
                            <TableCell>{item.subcategory}</TableCell>
                            <TableCell>{item.observations}</TableCell>
                            <TableCell>{item.evidence_id}</TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.updatedAt}</TableCell>
                            <TableCell>{item.filename}</TableCell>
                            <TableCell>{item.event_date}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ color: 'red' }}
                            colSpan={9}
                          >
                            No hay resumen de cambios disponible
                          </TableCell>
                          <TableCell align="center">
                            {data ? (
                              <Button onClick={() => handleDownloadFile(data)}>
                                Ver
                              </Button>
                            ) : (
                              <span>No disponible </span>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableBody>
          </Table>
        ))}
      </TableContainer>
    </Fragment>
  )
}

const Clients = ({ resume }) => {
  return (
    <Fragment>
      {resume.map((row, index) => {
        return Object.entries(row).map(([clientName, clientData]) => (
          <Row key={index} clientName={clientName} clientData={clientData} />
        ))
      })}
    </Fragment>
  )
}

export default Clients
