import { Button } from '@mui/material'

export const columns = [
  {
    field: 'createdAt',
    headerName: 'Fecha de Creación',
    width: 200,
    headerAlign: 'center', // Alineación del encabezado al centro

    align: 'center',
    valueGetter: (params) => new Date(params.row.createdAt).toLocaleString(),
  },
  {
    field: 'dimension',
    headerName: 'Dimensión',
    width: 300,
    align: 'center',
    headerAlign: 'center', // Alineación del encabezado al centro
  },
  // { field: 'category', headerName: 'Categoría', width: 300, align: 'center' },
  // {
  //   field: 'subcategory',
  //   headerName: 'Subcategoría',
  //   width: 250,
  //   align: 'center',
  // },
  {
    field: 'event_date',
    headerName: 'Fecha del Evento',
    width: 300,
    headerAlign: 'center', // Alineación del encabezado al centro
    align: 'center',
    valueGetter: (params) =>
      new Date(params.row.event_date).toLocaleDateString(),
  },
  {
    field: 'evidence',
    headerName: 'Adjuntos',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      const evidences = params.row.evidence_filename

      if (evidences && evidences.length > 0) {
        return (
          <div>
            {evidences.map((evidence, index) => (
              <Button
                key={index}
                style={{ marginRight: '5px' }}
                onClick={() => window.open(evidence, '_blank')}
              >
                Documento {index + 1}
              </Button>
            ))}
          </div>
        )
      } else {
        return 'No hay evidencia'
      }
    },
  },

  // {
  //   field: 'impact_consequence',
  //   headerName: 'Impacto y/o Consecuencia',
  //   width: 200,
  //   align: 'center',
  // },
  // {
  //   field: 'involved_name',
  //   headerName: 'Involucrado',
  //   width: 150,
  //   align: 'center',
  //   renderCell: (params) => {
  //     const involved = params.row.involved_name
  //     const name = involved ? involved.name : ''

  //     return name
  //   },
  // },
  // {
  //   field: 'mitigation_measures',
  //   headerName: 'Medidas de Mitigación',
  //   width: 250,
  //   align: 'center',
  // },
  // {
  //   field: 'need_for_extension',
  //   headerName: 'Más Plazo',
  //   width: 100,
  //   align: 'center',
  //   valueGetter: (params) => (params.row.need_for_extension ? 'Sí' : 'No'),
  // },
  // {
  //   field: 'more_price',
  //   headerName: 'Mayor Precio',
  //   width: 100,
  //   align: 'center',
  //   valueGetter: (params) => (params.row.more_price ? 'Sí' : 'No'),
  // },
  {
    field: 'observations',
    headerName: 'Observaciones',
    width: 500,
    headerAlign: 'center', // Alineación del encabezado al centro

    align: 'center',
  },
]
