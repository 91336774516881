import React, { useState } from 'react'
import { DropzoneDialog } from 'mui-file-dropzone'
import Button from '@mui/material/Button'
import { Box, Grid, Typography } from '@mui/material'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
const Dropzone = ({ onEvidencesChange, events }) => {
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = (files) => {
    setFiles(files)
    onEvidencesChange(files)
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Grid mt={3} className={!events.category ? 'oculto' : 'mostrar'}>
      <Button variant="text" fullWidth onClick={handleOpen}>
        {' '}
        {<DriveFolderUploadIcon />} Agregar evidencias
      </Button>

      <DropzoneDialog
        dialogTitle="Selecciona los archivos que desees subir"
        dropzoneText="Arrastra los archivos o haz clic"
        open={open}
        onSave={handleSave}
        acceptedFiles={[]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
        submitButtonText="Adjuntar"
        cancelButtonText="Cancelar"
      />
      <Box component="span" sx={{ display: 'block' }}>
        {files.map((file) => (
          <Typography key={file.path}> {file.path} </Typography>
        ))}
      </Box>
    </Grid>
  )
}

export default Dropzone
