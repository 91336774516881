import { Grid } from '@mui/material'
import SelectedClient from '../../../Common/SelectedClient'

export const ClientSelector = ({ clients, onChange }) => (
  <Grid item xs={12} sm={6} lg={5.96}>
    <SelectedClient
      title="Cliente"
      clients={clients}
      label="Seleccionar cliente"
      type="project"
      onChange={onChange}
    />
  </Grid>
)
