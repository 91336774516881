import { Box, Button } from '@mui/material'
import CreateNewProjectFields from './CreateNewProjectFields'
import SelectedProject from './SelectedProject'

const ToggleComponentProject = ({
  flag,
  onToggle,
  primaryIcon,
  secondaryIcon,
  primaryLabel,
  secondaryLabel,
  data,
  handleProjectChange,
  handleProjectChangeForm,
  setProjectSelected,
  clientId,
}) => {
  console.log(data)
  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Button
        variant="outlined"
        color="primary"
        onClick={onToggle}
        startIcon={flag ? primaryIcon : secondaryIcon}
      >
        {flag ? primaryLabel : secondaryLabel}
      </Button>

      {flag ? (
        <Box>
          {Object.keys(data).length === 0 ? (
            <CreateNewProjectFields
              data={data}
              label="Crear nuevo proyecto para este cliente"
              handleProjectChange={handleProjectChange}
              setProjectSelected={setProjectSelected}
              clientId={clientId}
            />
          ) : (
            <SelectedProject
              data={data}
              onChange={handleProjectChangeForm}
              label="Seleccione el proyecto"
              setProjectSelected={setProjectSelected}
              clientId={clientId}
            />
          )}
        </Box>
      ) : (
        Object.keys(data).length !== 0 && (
          <CreateNewProjectFields
            data={[data]}
            label="Crear nuevo proyecto para este cliente"
            handleProjectChange={handleProjectChange}
          />
        )
      )}
    </Box>
  )
}

export default ToggleComponentProject
