import { Fragment, useState } from 'react'
import { generateSlug } from '../../../../utils/generateSlug'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { convertDate } from '../../../../utils/formatedDate'
import { Link } from 'react-router-dom'
export const Row = ({ project, clientName }) => {
  const {
    name,
    type,
    slug,
    price,
    time,
    place,
    updatedAt,
    id,
    event_count,
    project_id,
    client_id,
  } = project
  const [open, setOpen] = useState(false)
  const projectName = generateSlug(name)
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">{convertDate(updatedAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalles del Proyecto
              </Typography>
              <Table size="small" aria-label="project-details">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tipo de Proyecto</TableCell>
                    <TableCell align="center">Monto</TableCell>
                    <TableCell align="center">Plazo</TableCell>
                    <TableCell align="center">Ubicación</TableCell>
                    <TableCell align="center">Eventos Registrados</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={id}>
                    <TableCell align="center" component="th" scope="row">
                      {type}
                    </TableCell>
                    <TableCell align="center">{price}</TableCell>
                    <TableCell align="center">{time}</TableCell>
                    <TableCell align="center">{place}</TableCell>
                    <TableCell align="center">{event_count}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Grid display="flex" justifyContent="end" marginY={2}>
              <Link to={`/proyectos/${slug}/${project_id}`}>
                <Button variant="outlined">Ver más detalle</Button>
              </Link>
              <Link
                to={`/clausulas/${clientName}/${client_id}/${projectName}/${project_id}`}
              >
                <Button variant="outlined" style={{ marginLeft: '5px' }}>
                  Ver cláusulas
                </Button>
              </Link>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
