import { Divider, Grid } from '@mui/material'
import { ClauseField } from './ClauseField'

export const ClauseList = ({
  fields,
  handleFieldChange,
  handleKeyDown,
  handleRadioClick,
}) =>
  fields.map((field, fieldIndex) => (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      key={field.id}
      alignItems="end"
      rowSpacing={1}
    >
      {field.clauses.map((clause, clauseIndex) => (
        <ClauseField
          field={clause}
          fieldIndex={fieldIndex}
          clauseIndex={clauseIndex}
          handleFieldChange={handleFieldChange}
          handleKeyDown={handleKeyDown}
          handleRadioClick={handleRadioClick}
        />
      ))}
      <Divider />
    </Grid>
  ))
