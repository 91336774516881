import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { CATEGORIES, DIMENSIONS, SUBCATEGORIES } from '../../../utils/events.js'
import { useEffect, useState } from 'react'
import {
  getActiveClientsQuery,
  getAllInvolveds,
} from '../../../config/axios.js'
import Swal from 'sweetalert2'
import CreateNewEvent from './CreateNewEvent'
import { useNavigate } from 'react-router-dom'

/**
 * Componente Event que permite crear un nuevo evento.
 *
 * Muestra la lista de clientes e involucrados activos,
 * y permite crear un nuevo evento.
 *
 * @returns {JSX.Element}
 */
const Event = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])

  const [clients, setClients] = useState([])
  const [involveds, setInvolveds] = useState([])
  const [projects, setProjects] = useState({
    name: '',
  })
  /**
   * Obtiene los clientes activos y los involucrados al montar el componente
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { clients } = await getActiveClientsQuery('/client-api/clientes')
        const { involveds } = await getAllInvolveds(
          '/involved-api/involucrados'
        )

        setClients(clients)
        setInvolveds(involveds)
      } catch (error) {
        console.log(error)
        const { msg } = error.response?.data || {}
        const title =
          error.response?.status === 500
            ? 'No se encuentra autenticado. Por favor, inicie sesión'
            : 'Ha ocurrido un error'

        Swal.fire({
          icon: 'error',
          title: title,
          text: msg?.error || msg,
        })
      }
    }

    fetchData()
  }, [])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Crear Nuevo Evento</Typography>
          </div>
          <div>
            <Grid container>
              <Grid item xs={12}>
                <CreateNewEvent
                  setInvolveds={setInvolveds}
                  involveds={involveds}
                  projects={projects}
                  setProjects={setProjects}
                  setClients={setClients}
                  clients={clients}
                  dimension={DIMENSIONS}
                  categories={CATEGORIES}
                  subcategories={SUBCATEGORIES}
                />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default Event
