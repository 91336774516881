import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { useCategoryOrSubcategoryUpdate } from '../../../hooks/useCategoryOrSubcategoryUpdate'
import { OptionsButtons } from '../../Common/OptionsButtons'
import Dropzone from '../../dropzone/Dropzone'
import { CalendarDate } from '../../Common/CalendarDate'
import Selected from '../../Common/Selected'
import EventModalConfirm from '../../Common/EventModalConfirm'
import EventDetails from '../../Common/EventDetails'
import Swal from 'sweetalert2'
import AddIcon from '@mui/icons-material/Add'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ToggleComponent from '../../Common/ToggleComponent'
import {
  createNewEventQuery,
  getProjectsByClientIdQuery,
} from '../../../config/axios'
import {
  categoriesText,
  dimensionsText,
  subcategoriesText,
} from '../../../utils/helperTexts'
import { HomeContext } from '../../../context/HomeContext'
import ToggleComponentProject from './commons/ToggleComponentProject'
import { esValidForm } from '../../../utils/isValidForm'
import { isCategoryInDepends } from '../../../utils/isCategoryInDepends'

/**
 * Componente para crear un nuevo evento.
 *
 * Permite al usuario ingresar los detalles de un nuevo evento y enviar la información para ser almacenada.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {function} props.setClients - Función para actualizar la lista de clientes.
 * @param {Array} props.clients - Lista de clientes disponibles.
 * @param {Array} props.dimension - Lista de dimensiones disponibles.
 * @param {Array} props.categories - Lista de categorías disponibles.
 * @param {Array} props.subcategories - Lista de subcategorías disponibles.
 * @param {Array} props.involveds - Lista de involucrados disponibles.
 * @returns {JSX.Element} - Elemento JSX que representa el componente.
 */
const CreateNewEvent = ({
  setClients,
  clients,
  dimension,
  categories,
  subcategories,
  involveds,
}) => {
  const [auth] = useContext(HomeContext)
  const [category, setCategory] = useState([])
  const [subcategory, setSubcategory] = useState([])
  const [involvedSelected, setInvolvedSelected] = useState({})
  const [projectSelected, setProjectSelected] = useState({
    name: '',
    type: '',
    price: '',
    time: '',
    place: '',
    client_id: '',
  })

  const [involved, setInvolved] = useState(involveds)
  const [project, setProject] = useState({})

  const [flag, setFlag] = useState(true)
  const [flagProject, setFlagProject] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [events, setEvents] = useState({
    client_id: '',
    involved_id: '',
    dimension: '',
    category: '',
    subcategory: '',
    event_date: '',
    evidence: [],
    observations: '',
    project_id: '',
    mitigation_measures: '',
    impact_consequence: '',
    need_for_extension: false,
    more_price: false,
  })

  useCategoryOrSubcategoryUpdate(categories, events.dimension, setCategory)
  useCategoryOrSubcategoryUpdate(subcategories, events.category, setSubcategory)

  useEffect(() => {
    const getProjectsByClientId = async () => {
      try {
        const response = await getProjectsByClientIdQuery(
          '/projects-api/proyectos-por-id',
          events.client_id,
          auth.token
        )
        console.log(response)
        if (response.status === 200) {
          setProject(response.data.projects)
        } else {
          setProject({})
          setEvents({
            ...events,
            project_id: '',
          })
        }
        setFlag(true)
      } catch (error) {
        console.error(error)
      }
    }

    getProjectsByClientId()
  }, [events.client_id])

  useEffect(() => {
    setInvolved(involveds)
  }, [involveds])

  const handleChange = (e, val, setVal) => {
    setVal({
      ...val,
      [e.target.name]: e.target.value,
    })
  }

  const handleConfirm = async (event) => {
    const jwt = localStorage.getItem('token')
    const accessToken = localStorage.getItem('accessTokenQuery')
    const userId = localStorage.getItem('id')

    const data = {
      events,
      involvedSelected,
      project,
      projectSelected,
    }

    const formData = new FormData()

    for (const file of events.evidence) {
      formData.append('evidence', file)
    }
    try {
      setModalOpen(true)
      await createNewEventQuery(
        '/events-api/registrar-evento',
        formData,
        data,
        jwt,
        accessToken,
        userId
      )

      Swal.fire({
        icon: 'success',
        title: 'Evento registrado con éxito',
      })

      setEvents({
        client_id: '',
        project_id: '',
        involved_id: '',
        dimension: '',
        category: '',
        subcategory: '',
        event_date: '',
        evidence: [],
        observations: '',
        mitigation_measures: '',
        impact_consequence: '',
        need_for_extension: false,
        more_price: false,
      })

      setClients(clients)
      setModalOpen(false)
    } catch (error) {
      console.log(error)
      const { msg } = error.response.data
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: msg,
      })
      setModalOpen(false)
    }
  }

  const handleEvidencesChange = (files) => {
    setEvents((prevEvents) => ({
      ...prevEvents,
      evidence: [...prevEvents.evidence, ...files],
    }))
  }

  const handleOptionChange = (name, value) => {
    setEvents((prevEvents) => ({
      ...prevEvents,
      [name]: value,
    }))
  }

  const handleDateChange = (event) => {
    setEvents({
      ...events,
      [event.target.name]: event.target.value,
    })
  }

  const handleClientChange = (clientId) => {
    setEvents({ ...events, client_id: clientId })
  }

  const handleInvolvedChangeForm = (involvedId) => {
    setEvents({ ...events, involved_id: involvedId })
    setInvolvedSelected({})
  }

  const handleProjectChangeForm = (projectId) => {
    setEvents({ ...events, project_id: projectId })
    setProjectSelected({})
  }

  const handleInvolvedChange = (event) => {
    setInvolvedSelected({
      ...involvedSelected,
      [event.target.name]: event.target.value,
    })

    setEvents({
      ...events,
      involved_id: '',
    })
  }

  const handleProjectChange = (event) => {
    setProjectSelected({
      ...projectSelected,
      [event.target.name]: event.target.value,
    })

    setEvents({
      ...events,
      project_id: '',
    })
  }

  return (
    <FormControl autoComplete="off" noValidate fullWidth>
      <Card sx={{ px: 2, mx: 2 }}>
        <CardContent>
          <Box>
            <Grid container className="mostrar">
              <Selected
                title="Cliente"
                onChange={handleClientChange}
                data={clients}
                label="Seleccionar cliente"
              />
            </Grid>
            <Grid className={!events.client_id ? 'oculto' : 'mostrar'}>
              <h2>Seleccionar el proyecto</h2>
              <Grid
                sx={{
                  '& .MuiTextField-root': { mt: 1.5 },
                }}
              >
                <ToggleComponentProject
                  flag={flagProject}
                  onToggle={() => setFlagProject(!flagProject)}
                  textLabel="Seleccionar proyecto"
                  primaryIcon={<AddIcon />}
                  secondaryIcon={<FormatListBulletedIcon />}
                  primaryLabel="Registrar nuevo proyecto para este cliente"
                  secondaryLabel="Ver listado de proyectos de este cliente"
                  data={project}
                  handleProjectChange={handleProjectChange}
                  handleProjectChangeForm={handleProjectChangeForm}
                  type="project"
                  setProjectSelected={setProjectSelected}
                  clientId={events.client_id}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={!events.client_id ? 'oculto' : 'mostrar'}
            >
              <OptionsButtons
                options={dimension}
                name="dimension"
                selectedValue={events.dimension}
                onChange={handleOptionChange}
                direction="row"
                title="Dimensiones"
                subtitle={dimensionsText}
                responsive
              />
            </Grid>

            <Grid
              container
              className={!events.dimension ? 'oculto' : 'mostrar'}
            >
              <OptionsButtons
                options={category}
                name="category"
                selectedValue={events.category}
                onChange={handleOptionChange}
                direction="row"
                title="Categorías"
                subtitle={categoriesText}
                responsive
                rows="2"
              />
            </Grid>
            <Grid
              container
              className={
                !isCategoryInDepends(subcategories, events.category)
                  ? 'oculto'
                  : 'mostrar'
              }
            >
              <OptionsButtons
                options={subcategory}
                name="subcategory"
                selectedValue={events.subcategory}
                onChange={handleOptionChange}
                title="Subcategorías"
                subtitle={subcategoriesText}
                rows="2"
                direction="row"
                responsive
              />
            </Grid>
            <Grid className={!events.category ? 'oculto' : 'mostrar'}>
              <h2>Datos de la persona involucrada</h2>
              <Grid
                sx={{
                  '& .MuiTextField-root': { mt: 1.5 },
                }}
              >
                <ToggleComponent
                  flag={flag}
                  onToggle={() => setFlag(!flag)}
                  textLabel="Seleccionar a involucrado"
                  primaryIcon={<AddIcon />}
                  secondaryIcon={<FormatListBulletedIcon />}
                  primaryLabel="Registrar nueva persona involucrada"
                  secondaryLabel="Ver historial involucrados"
                  data={involved}
                  handleInvolvedChange={handleInvolvedChange}
                  handleInvolvedChangeForm={handleInvolvedChangeForm}
                  type="involved"
                />
              </Grid>
            </Grid>
            <EventDetails
              category="Observaciones"
              label="Observaciones"
              name="observations"
              onChange={(e) => handleChange(e, events, setEvents)}
              events={events}
            />
            <EventDetails
              category="Medidas de mitigación"
              label="Medidas de mitigación"
              name="mitigation_measures"
              onChange={(e) => handleChange(e, events, setEvents)}
              events={events}
            />
            <EventDetails
              category="Impacto o consecuencia proyectada"
              label="Impacto o consecuencia proyectada"
              name="impact_consequence"
              onChange={(e) => handleChange(e, events, setEvents)}
              events={events}
            />
            <FormControl className={!events.category ? 'oculto' : 'mostrar'}>
              <h2>Solicitudes</h2>
              <RadioGroup
                onClick={() =>
                  setEvents({
                    ...events,
                    need_for_extension: !events.need_for_extension,
                  })
                }
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={true}
              >
                <FormControlLabel
                  name="need_for_extension"
                  value={events.need_for_extension === true ? true : false}
                  control={<Radio />}
                  label="Solicitud de aumento plazo"
                />
              </RadioGroup>

              <RadioGroup
                onClick={() =>
                  setEvents({ ...events, more_price: !events.more_price })
                }
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={true}
              >
                <FormControlLabel
                  name="more_price"
                  value={events.more_price === true ? true : false}
                  control={<Radio />}
                  label="Solicitud de aumento de precio"
                />
              </RadioGroup>
            </FormControl>
            <Grid
              className={!events.category ? 'oculto' : 'mostrar'}
              container
              display="flex"
              justifyContent="center"
              mt={2}
            >
              <CalendarDate name="event_date" handleChange={handleDateChange} />
            </Grid>
            <Dropzone
              events={events}
              onEvidencesChange={handleEvidencesChange}
            />
          </Box>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <EventModalConfirm
            title="Confirmación de registro"
            open={modalOpen}
            confirm={handleConfirm}
            handleOpen={() => setModalOpen(true)}
            handleClose={() => setModalOpen(false)}
            event={events}
            type="event"
            button={'no'}
            isDisabled={!esValidForm(events, 'event')}
          />
        </CardActions>
      </Card>
    </FormControl>
  )
}

export default CreateNewEvent
