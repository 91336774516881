export const generateClauseOptions = (fields) => {
  let options = [];
  fields.forEach((field, fieldIndex) => {
    if (Array.isArray(field?.clauses)) {
      field.clauses.forEach((clause, clauseIndex) => {
        options.push({
          label: `${clause.clause} - ${clause.observation}`,
          fieldIndex,
          clauseIndex,
        });
      });
    }
  });
  return options;
}