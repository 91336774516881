import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const CategorySelector = ({
  categories,
  onChange,
  selectedCategory,
}) => (
  <FormControl fullWidth>
    <InputLabel id="category-select-label">Seleccionar Categoría</InputLabel>
    <Select
      labelId="category-select-label"
      id="category-select"
      onChange={onChange}
      label="Seleccionar Categoría"
      disabled={categories.length === 0}
      value={selectedCategory}
    >
      {categories.map((category, index) =>
        category.values.map((value, index) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))
      )}
    </Select>
  </FormControl>
)
