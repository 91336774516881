import React from 'react'
import { Grid, TextField } from '@mui/material'

const EventDetails = ({ category, label, name, onChange, events }) => {
  return (
    <Grid
      item
      xs={12}
      container
      className={!events.category ? 'oculto' : 'mostrar'}
    >
      <h2>{category}</h2>
      <Grid
        sx={{
          '& .MuiTextField-root': { mt: 1.5 },
        }}
      >
        <TextField
          size="medium"
          fullWidth
          variant="outlined"
          label={label}
          name={name}
          required
          onChange={onChange}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  )
}

export default EventDetails
