export const properties = [
  'Fecha de cambio',
  'Dimension',
  'Categoria',
  'Subcategoria',
  'Fecha del evento problema',
  'Involucrados',
  'Observaciones',
  'Adjuntos',
  'Medidas de mitigación',
  'Impacto y/o consecuencia proyectada',
  'Más plazo',
  'Mayor precio',
  'Fecha de creación',
]

export const DIMENSIONS = [
  'Información del proyecto',
  'Contraparte y comunicaciones',
  'Leyes y permisos',
  'Sitio de la obra',
  'Alcance de la obra',
  'Eventos imprevistos',
  'Atrasos',
  'Entregas y recepciones',
  'Subcontratos',
  'Pagos',
  'Disputas',
  'Inspección Técnica de Obras',
  'Problemas con Comunidades Locales',
]

export const CATEGORIES = [
  {
    depends: 'Información del proyecto',
    values: [
      'Información proporcionada es incompleta',
      'Información proporcionada es errada',
    ],
  },
  {
    depends: 'Contraparte y comunicaciones',
    values: [
      'Contraparte proyecta inexperiencia',
      'Demora en respuestas de comunicaciones',
      'Incumplimiento Procedimiento de Comunicaciones',
      'Comunicación agresiva o amenazante ',
      'Comunición ambigua',
    ],
  },
  {
    depends: 'Leyes y permisos',
    values: [
      'Incumplimiento de leyes, permisos o regulaciones',
      'Inconvenientes en la Obtención de Permisos',
      'Amenaza de cancelación de permiso',
      'Rechazo de Permisos',
      'Cancelación de Permisos',
    ],
  },
  {
    depends: 'Sitio de la obra',
    values: [
      'Características diferentes a las informadas ',
      'Acceso imposible o complejo',
      'Restricción parcial o total de acceso al Sitio',
      'Problemas de Seguridad',
      'Interferencias con otros contratistas o proyectos',
    ],
  },
  {
    depends: 'Alcance de la obra',
    values: [
      'Solicitud de trabajo adicional, fuera de alcance original',
      'Solicitud de trabajo adicional, dentro de alcance original',
    ],
  },
  {
    depends: 'Eventos imprevistos',
    values: [
      'Verificación de hecho que impacta en el cumplimiento de hitos',
      'Verificación de Caso Fortuito o Fuerza Mayor según Contrato',
      'Suspensión de la obra por contraparte o tercero',
      'Entrega de materiales por Proveedores',
      'Incumplimiento de Representaciones y Garantías',
      'Calidad de Suministros o Materiales',
      'Defectos Constructivos',
      'Cambios en situación financiera de la Compañía',
      'Problemas en Cumplimiento Costos Financieros',
      'Otros',
    ],
  },
  {
    depends: 'Atrasos',
    values: [
      'Cumplimiento de Programa',
      'Aprobación de Hitos',
      'Aprobación de Documentos',
      'Conducción de Inspecciones',
      'Recepción Parcial o Definitiva de Obra',
      'Otros',
    ],
  },
  {
    depends: 'Inspección y Recepciones',
    values: [
      'Negativa a Aprobación de Hito',
      'Negativa a Recepción Parcial o Definitiva de Obra',
      'Negativa a conducir inspecciones',
    ],
  },
  {
    depends: 'Subcontratos',
    values: [
      'Incumplimiento de Obligaciones contractuales',
      'Ejecución de obra defectuosa',
      'Interrupción o Suspensión de Obras',
      'Problemas con subcontratistas',
      'Otros',
    ],
  },
  {
    depends: 'Pagos',
    values: [
      'Atraso en pagos',
      'Pago parcial o incompleto',
      'Pago efectuado sin respetar procedimiento contractual',
      'Objeción o rechazo de cobro de factura',
    ],
  },
  {
    depends: 'Disputas',
    values: [
      'Contraparte alega incumplimiento contractual',
      'Contraparte se niega a cumplir obligaciones',
      'Contrapate amenaza con aplicación de remedios contractuales',
      'Contraparte Amenaza con Término Anticipado del Contrato',
      'Contraparte amenaza con demandar',
    ],
  },
]

export const SUBCATEGORIES = [
  {
    depends: 'Cumplimiento de Programa',
    values: [
      'Por factores bajo el control de la Compañía',
      'Por factores fuera del control de la Compañía',
    ],
  },
  {
    depends: 'Aprobación de Hitos',
    values: [
      'Atraso de Contraparte o Terceros en Aprobación de Hitos',
      'Atraso generado por rechazo de Hito por Contraparte o Tercero',
    ],
  },
  {
    depends: 'Aprobación de Documentos',
    values: [
      'Atraso de Contraparte o Terceros en Aprobación de Documentos',
      'Atraso generado por rechazo de Documento por Contraparte o Tercero',
    ],
  },
  {
    depends: 'Solicitud de trabajo adicional, fuera de alcance original',
    values: [
      'Solicitud conforme al contrato, sin impacto plazo/precio',
      'Solicitud conforme al contrato, con impacto plazo/precio',
      'Solicitud no conforme al contrato, sin impacto plazo/precio',
      'Solicitud no conforme al contrato, con impacto plazo/precio',
    ],
  },
  {
    depends: 'Solicitud de trabajo adicional, dentro de alcance original',
    values: [
      'Solicitud conforme al contrato, sin impacto plazo/precio',
      'Solicitud conforme al contrato, con impacto plazo/precio',
      'Solicitud no conforme al contrato, sin impacto plazo/precio',
      'Solicitud no conforme al contrato, con impacto plazo/precio',
    ],
  },
]

export const event = [
  {
    dimension: 'Información del proyecto',
    categories: {
      firstCategory:
        'Información del proyecto presentan diferencias en comparación con lo informado en el Contrato y/o negociaciones',
      secondCategory:
        'La ubicación del Proyecto es diferente a la informada en el Contrato y/o las negociaciones',
      thirdCategory:
        'Información entregada en el Contrato y/o negociaciones es incompleta o no refleja correctamente las Información del proyecto ',
      fourthCategory:
        'Las Representaciones y Garantías no se corresponden con las Información del proyecto o no se cumplieron',
      fifthCategory:
        'El nivel de complejidad del Proyecto es diferente al informado en el Contrato y/o durante las negociaciones ',
      sixthCategory:
        'La contraparte o un tercero se ha demorado en la entrega de la Ingeniería del Proyecto, o cualquier otro documento necesario para la ejecución de la obra',
    },
    event_date: '',
    involded: '',
    observations: 'string (255 characters)',
    mitigation_measures: 'string (255 characters)',
    impact_consequence: 'string (255 characters)',
    need_for_extension: 'string (255 characters)',
    more_price: 'string (255 characters)',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
]
