import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const DimensionSelector = ({
  dimensions,
  onChange,
  selectedDimension,
}) => (
  <FormControl fullWidth>
    <InputLabel id="dimension-select-label">Seleccionar Dimensión</InputLabel>
    <Select
      labelId="dimension-select-label"
      id="dimension-select"
      onChange={onChange}
      label="Seleccionar Dimensión"
      value={selectedDimension}
    >
      {dimensions.map((dimension, index) => (
        <MenuItem key={index} value={dimension}>
          {dimension}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)
