export const columnsClauses = [
  {
    field: 'clause',
    headerName: 'Cláusula',
    width: 200,
    headerAlign: 'center', // Alineación del encabezado al centro
    align: 'center',
  },
  {
    field: 'observation',
    headerName: 'Observación',
    width: 300,
    align: 'center',
    headerAlign: 'center', // Alineación del encabezado al centro
  },
  {
    field: 'dimension',
    headerName: 'Dimensión',
    width: 400,
    align: 'center',
    headerAlign: 'center', // Alineación del encabezado al centro
  },
  {
    field: 'category',
    headerName: 'Categoría',
    width: 400,
    align: 'center',
    headerAlign: 'center', // Alineación del encabezado al centro
  },
  {
    field: 'subcategory',
    headerName: 'Subcategoría',
    width: 400,
    align: 'center',
    headerAlign: 'center', // Alineación del encabezado al centro
  },
]
