import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useState } from 'react'
import { useMemo } from 'react'
import { generateClauseOptions } from '../functions/generateClauseOptions'
import { getStyles } from '../functions/getStyles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const ClausesSelector = ({ fields = [], handleClauseClick }) => {
  const theme = useTheme()
  const [selectedClauses, setSelectedClauses] = useState([])

  const clauseOptions = useMemo(() => generateClauseOptions(fields), [fields])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    const previouslySelected = [...selectedClauses]
    setSelectedClauses(
      typeof value === 'string' ? value.split(',') : value
    )

    // Determina qué cláusula fue seleccionada o deseleccionada
    let changedClause = null
    if (value.length > previouslySelected.length) {
      // Una nueva cláusula fue seleccionada
      changedClause = value.find(
        (clause) => !previouslySelected.includes(clause)
      )
    } else {
      // Una cláusula fue deseleccionada
      changedClause = previouslySelected.find(
        (clause) => !value.includes(clause)
      )
    }

    if (handleClauseClick && changedClause) {
      const fieldIndex = 0 // Como tienes un solo field en el ejemplo
      const clauseIndex = fields[fieldIndex].clauses.findIndex(
        (clause) => `${clause.clause} - ${clause.observation}` === changedClause
      )

      if (clauseIndex !== -1) {
        handleClauseClick(fieldIndex, clauseIndex)
      }
    }
  }

  return (
    <div>
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel id="demo-multiple-chip-label">Cláusulas</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedClauses}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Cláusulas" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {clauseOptions.length ? (
            clauseOptions.map((option) => (
              <MenuItem
                key={option.label}
                value={option.label}
                style={getStyles(option.label, selectedClauses, theme)}
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No se ha registrado una cláusula
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}

export default ClausesSelector
