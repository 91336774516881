import { useCallback, useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { getProjectsByIdQuery } from '../../../config/axios'
import { useParams } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Loading from '../../../sections/loading/Loading'
import { columns } from '../../../utils/columns'
import NotHaveEvents from './NotHaveEvents'
import { useTheme } from '@mui/material/styles'
import { CalendarDate } from '../../Common/CalendarDate'
import EventModalConfirm from '../../Common/EventModalConfirm'
import { groupEventsById } from './functions/groupEventsById'

export const DataTable = () => {
  const { id } = useParams()
  const [rows, setRows] = useState([])
  const [flag, setFlag] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const getClient = useCallback(async () => {
    try {
      const { data } = await getProjectsByIdQuery(
        '/events-api/proyectos-historicos',
        id
      )
      setRows(groupEventsById(data.results))

      setFlag(true)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    getClient()
  }, [getClient])

  const handleStartDateChange = (e) => {
    setStartDate(e.value)
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.value)
  }

  const filteredEvents = rows.filter((event) => {
    const eventDate = new Date(event.event_date)
    return (
      (!startDate || eventDate >= startDate) &&
      (!endDate || eventDate <= endDate)
    )
  })

  console.log(rows)

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      {flag ? (
        <Container>
          <Stack spacing={3}>
            {rows.length === 0 ? (
              <NotHaveEvents
                buttonText="Agregar evento"
                redirect="/ingresar-evento"
                text="Aún no tienes eventos asociados a este proyecto"
              />
            ) : (
              <>
                <div>
                  <Typography variant="h3" textAlign="center">
                    {rows[0].project_name}
                  </Typography>
                </div>
                <Stack spacing={1}>
                  <Typography variant="h4">Eventos</Typography>
                  <Stack>
                    <Typography>
                      En esta tabla puedes ver todos los eventos asociado a cada
                      proyecto
                    </Typography>
                    <Grid display="flex" justifyContent="end">
                      <Grid
                        marginRight={2}
                        sx={{ width: isSmallScreen ? '100%' : '30%' }}
                      >
                        <CalendarDate
                          value={startDate}
                          handleChange={handleStartDateChange}
                        />
                      </Grid>
                      <Grid sx={{ width: isSmallScreen ? '100%' : '30%' }}>
                        <CalendarDate
                          value={endDate}
                          handleChange={handleEndDateChange}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <div>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <DataGrid
                        rows={filteredEvents}
                        columns={columns}
                        pageSize={1}
                        onRowDoubleClick={(params) => {
                          setSelectedEvent(params.row)
                          setOpen(true)
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                      />
                    </Grid>
                  </Grid>
                </div>
                <EventModalConfirm
                  button={'yes'}
                  open={open}
                  handleClose={() => setOpen(false)}
                  event={selectedEvent}
                  title="Detalles del evento"
                  type="event"
                />
              </>
            )}
          </Stack>
        </Container>
      ) : (
        <Loading />
      )}
    </Box>
  )
}
