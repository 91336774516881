import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProjectInfoDetail from './ProjectInfoDetail'
import { createNewProjectQuery } from '../../../config/axios'
import Swal from 'sweetalert2'
import SelectedClient from '../../Common/SelectedClient'

const CreateNewProjectDetails = ({ clients }) => {
  useEffect(() => {}, [])

  const [project, setProject] = useState({
    name: '',
    type: '',
    price: '',
    time: '',
    place: '',
    client_id: '',
    active: 1,
  })

  const createProject = async (project) => {
    try {
      await createNewProjectQuery('/projects-api/crear-proyecto', project)
      Swal.fire({
        icon: 'success',
        title: 'Proyecto creado correctamente',
        showConfirmButton: false,
        timer: 1500,
      })

      setProject({ name: '', type: '', price: '', time: '', place: '' })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: 'Revise los campos ingresados e intente nuevamente. En caso de persistir, comuníquese con el administrador',
      })
    }
  }

  const handleInputChange = (event) => {
    setProject({
      ...project,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isValidProject(project)) {
      createProject(project)
    }
  }

  const handleClientChange = (clientId) => {
    console.log(clientId.target.value)
    setProject({ ...project, client_id: clientId.target.value })
  }

  function isValidProject(project) {
    return (
      !!project.name &&
      !!project.type &&
      !!project.price &&
      !!project.time &&
      !!project.place
    )
  }
  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card sx={{}}>
        <CardHeader subheader="Debes ingresar los datos en los campos respectivos" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ mt: 0 }}>
            <Grid container display="flex" justifyContent="center">
              <Grid xs={12} md={11.2} margin={1}>
                <SelectedClient
                  title="Cliente"
                  onChange={handleClientChange}
                  clients={clients}
                  label="Seleccionar cliente"
                  type="project"
                />
              </Grid>

              <Grid xs={12} md={5.5} margin={1}>
                <ProjectInfoDetail
                  handleInputChange={handleInputChange}
                  name="name"
                  value={project.name}
                  label="Ingrese nombre del proyecto"
                />
              </Grid>
              <Grid xs={12} md={5.5} margin={1}>
                <ProjectInfoDetail
                  handleInputChange={handleInputChange}
                  name="type"
                  value={project.type}
                  label="Ingrese tipo de proyecto"
                />
              </Grid>
              <Grid xs={12} md={5.5} margin={1}>
                <ProjectInfoDetail
                  handleInputChange={handleInputChange}
                  name="price"
                  value={project.price}
                  label="Ingrese precio del proyecto"
                />
              </Grid>
              <Grid xs={12} md={5.5} margin={1}>
                <ProjectInfoDetail
                  handleInputChange={handleInputChange}
                  name="time"
                  value={project.time}
                  label="Ingrese duración del proyecto"
                />
              </Grid>
              <Grid xs={12} md={5.5} margin={1}>
                <ProjectInfoDetail
                  handleInputChange={handleInputChange}
                  name="place"
                  value={project.place}
                  label="Ingrese lugar del proyecto"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            disabled={!isValidProject(project)}
            type="submit"
            variant="contained"
          >
            Crear
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}

export default CreateNewProjectDetails
