import React, { Fragment } from 'react'
import { List, ListItem, ListItemText, Box } from '@mui/material'

export const HistoryClauses = ({ historyClauses }) => {
  return (
    <List>
      {historyClauses && historyClauses.length > 0 ? (
        historyClauses.map((group, index) => (
          <Fragment key={index}>
            <Box my={2} bgcolor="grey.200" borderRadius="5px" p={2}>
              <ListItem>
                <ListItemText
                  primary={`Asignado a: ${group.asignedTo}`}
                  primaryTypographyProps={{ variant: 'h6' }}
                />
              </ListItem>
              <List component="div" disablePadding>
                {group.clauses.map((clause, clauseIndex) => (
                  <ListItem key={clauseIndex} style={{ paddingLeft: '25px' }}>
                    <ListItemText
                      primary={`Cláusula: ${clause.clause}`}
                      secondary={`Observación: ${clause.observation}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Fragment>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No hay cláusulas registradas anteriormente." />
        </ListItem>
      )}
    </List>
  )
}
