import { useEffect } from 'react';


export const useCategoryOrSubcategoryUpdate = (fildToFound, filter, setFieldToFound) => {
  useEffect(() => {
    for (let i = 0; i < fildToFound.length; i++) {
      if (fildToFound[i].depends === filter) {
        const values = fildToFound[i].values;
        setFieldToFound(values);
        break;
      }
      else {
        setFieldToFound(['No aplica']);
      }
      
    }
  }, [filter, fildToFound, setFieldToFound]);

};