import React from 'react'
import { Grid, Button } from '@mui/material'

export const ClauseSection = ({ isButtonDisabled, handleClick }) => (
  <Grid
    item
    xs={12}
    sm={6}
    lg={12}
    marginY={2}
    display="flex"
    justifyContent="end"
  >
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={isButtonDisabled}
    >
      Asignar clásulas
    </Button>
  </Grid>
)
