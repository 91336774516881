import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const SubcategorySelector = ({
  subcategories,
  onChange,
  selectedSubcategory,
}) => (
  <FormControl fullWidth>
    <InputLabel id="subcategory-select-label">
      Seleccionar Subcategoría
    </InputLabel>
    <Select
      labelId="subcategory-select-label"
      id="subcategory-select"
      onChange={onChange}
      label="Seleccionar Categoría"
      disabled={subcategories.length === 0}
      value={selectedSubcategory}
    >
      {subcategories.map((subcategory, index) =>
        subcategory.values.map((value, index) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))
      )}
    </Select>
  </FormControl>
)
