import { TextField } from '@mui/material'
import React from 'react'

const ProjectInfoDetail = ({ handleInputChange, name, value, label }) => {
  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      onChange={handleInputChange}
      required
      value={value}
      type="text"
    />
  )
}

export default ProjectInfoDetail
