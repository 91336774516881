export const generateSlug = (text) => {
  const slug = text
  .toLowerCase() // Convertir a minúsculas
  .normalize("NFD") // Descomponer caracteres con diacríticos en letras base y marcas diacríticas
  .replace(/[\u0300-\u036f]/g, "") // Eliminar marcas diacríticas
  .replace(/[^\w\s-]/g, "") // Remover caracteres no alfanuméricos excepto espacios y guiones
  .replace(/\s+/g, "-") // Reemplazar espacios en blanco por guiones
  .replace(/-+/g, "-") // Remover guiones duplicados
  .trim(); // Eliminar espacios en blanco al principio y al final
  return slug
} 