import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeContext } from '../../../context/HomeContext'
import {} from '../../../config/axios'

import { Box, Container, Grid, Stack, Typography } from '@mui/material'

import DashboardDetails from '../dashboard/DashboardDetails'

const Home = () => {
  // Obtener el contexto de inicio de sesión
  const [auth] = useContext(HomeContext)
  const navigate = useNavigate()

  useEffect(() => {
    // Verificar la autenticación y redirigir si no está autenticado
    if (
      !localStorage.getItem('token') ||
      !localStorage.getItem('name') ||
      !localStorage.getItem('email') ||
      !localStorage.getItem('accessTokenQuery')
    ) {
      localStorage.clear()
      navigate('/iniciar-sesion')
    }
  }, [navigate, auth.id])

  return (
    <Box flexGrow={1} display="flex">
      <Container>
        <Stack spacing={3}>
          <div>
            <Typography variant="h4" textAlign="center">
              DASHBOARD
            </Typography>
          </div>
          <div>
            <Grid container>
              <Grid item={true} xs={12} md={12}>
                <Grid item={true} xs={12} md={12}></Grid>
                <DashboardDetails />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default Home
